export default {
  data() {
    return { errorStatus: "", errorStatusText: "", errorDataResponse: "" };
  },
  methods: {
    errorHandling(err) {
      console.error(err);
      // if (err.response.status == 401) {
      //   localStorage.removeItem("token");
      //   localStorage.removeItem("adminToken");
      //   this.$store.dispatch("token", null);
      //   this.$store.dispatch("adminToken", null);
      //   this.$router.push("/login");
      // } else {
      //   this.errorStatus = err.response.status;
      //   this.errorStatusText = err.response.statusText;
      //   if (err.response.data.response) {
      //     this.errorDataResponse = err.response.data.response;
      //   } else if (err.response.data.message) {
      //     this.errorDataResponse = err.response.data.message;
      //   }
      // }
    },
  },
};
