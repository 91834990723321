import { createRouter, createWebHistory } from "vue-router";
import store from "../store";

const routes = [
  {
    path: "/invoices",
    name: "Invoices",
    component: () => import("../views/Invoices.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/documents",
    name: "Documents",
    component: () => import("../views/Documents.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/origins/:presentationKey",
    name: "ExpertStatistics",
    props: true,
    component: () => import("../views/PBX/ExpertStatistics.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/originDetails/:presentationKey",
    name: "ExpertStatisticsOriginDetail",
    props: true,
    component: () => import("../views/PBX/ExpertStatisticsOriginDetail.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/answered/:presentationKey",
    name: "ExpertStatisticsAnsweredUnanswered",
    props: true,
    component: () =>
      import("../views/PBX/ExpertStatisticsAnswerUnansweredCalls.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/answeredDaily/:presentationKey",
    name: "ExpertStatisticsAnsweredUnansweredDaily",
    props: true,
    component: () =>
      import("../views/PBX/ExpertStatisticsAnswerUnansweredDailyCalls.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/report/:presentationKey",
    name: "ExpertStatisticsQueueDetail",
    props: true,
    component: () =>
      import("../views/PBX/ExpertStatisticsQueueTableDetail.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/didReport/:presentationKey",
    name: "ExpertStatisticsDIDReport",
    props: true,
    component: () =>
      import("../views/PBX/ExpertStatisticsDIDReport.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/dashboard/:presentationKey",
    name: "ExpertStatisticsDashboard",
    props: true,
    component: () =>
      import("../views/PBX/ExpertStatisticsDashboard.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/queuesComparison/:presentationKey",
    name: "ExpertStatisticsQueueComparator",
    props: true,
    component: () =>
      import("../views/PBX/ExpertStatisticsQueueComparator.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/:pathMatch(.*)*",
    name: "404",
    component: () => import("../components/Error404.vue"),
    meta: {
      requiresAuth: true,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

let entryUrl = null;

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (entryUrl) {
      const url = entryUrl;
      entryUrl = null;
      return next(url);
    }
    next();
    return;
    entryUrl = to.path;
    next("/");
  } else {
    next();
  }
});

router.beforeResolve((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    next();
    return;
    next("/");
  } else {
    next();
  }
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresNotLogged)) {
    next();
    return;
    next("/");
  } else {
    next();
  }
});

export default router;
