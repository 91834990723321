import { createStore } from "vuex";

export default createStore({
  state: {
    showBanner: false,
    search: false,
    user: {},
    avatar: localStorage.getItem("avatar") || "",
    token: localStorage.getItem("token") ? localStorage.getItem("token") : null,
    adminToken: localStorage.getItem("adminToken") || null,
    sidebar: false,
    account: localStorage.getItem("account") || null,
    language: localStorage.getItem("language") || "fr",
    logo: localStorage.getItem("logo") || null,
    pbxElements: undefined,
    pbxTimeSelected: null,
    pbxTimeSelectedEnd: null,
    buttonSelector: null,
    hostName: localStorage.getItem("hostName") || null,
    hostContext: localStorage.getItem("hostContext") || 0,
    hostTimeZone: 0,
    dataSet: undefined,
  },
  mutations: {
    showBanner(state, showBanner) {
      state.showBanner = showBanner;
    },
    user(state, user) {
      state.user = user;
    },
    token(state, token) {
      state.token = token;
    },
    adminToken(state, adminToken) {
      state.adminToken = adminToken;
    },
    avatar(state, avatar) {
      state.avatar = avatar;
    },
    account(state, account) {
      state.account = account;
    },
    language(state, language) {
      state.language = language;
    },
    search(state, search) {
      state.search = search;
    },
    logo(state, logo) {
      state.logo = logo;
    },
    pbxElements(state, pbxElements) {
      state.pbxElements = pbxElements;
    },
    pbxTimeSelected(state, pbxTimeSelected) {
      state.pbxTimeSelected = pbxTimeSelected;
    },
    pbxTimeSelectedEnd(state, pbxTimeSelectedEnd) {
      state.pbxTimeSelectedEnd = pbxTimeSelectedEnd;
    },
    buttonSelector(state, buttonSelector) {
      state.buttonSelector = buttonSelector;
    },
    hostName(state, hostName) {
      state.hostName = hostName;
    },
    hostContext(state, hostContext) {
      state.hostContext = hostContext;
    },
    hostTimeZone(state, hostTimeZone) {
      state.hostTimeZone = hostTimeZone;
    },
    dataSet(state, dataSet) {
      state.dataSet = dataSet;
    },
  },
  actions: {
    showBanner(context, showBanner) {
      context.commit("showBanner", showBanner);
    },
    user(context, user) {
      context.commit("user", user);
    },
    token(context, token) {
      context.commit("token", token);
    },
    adminToken(context, adminToken) {
      context.commit("adminToken", adminToken);
    },
    userAvatar(context, avatar) {
      context.commit("avatar", avatar);
    },
    account(context, account) {
      context.commit("account", account);
    },
    language(context, language) {
      context.commit("language", language);
    },
    search(context, search) {
      context.commit("search", search);
    },
    logo(context, logo) {
      context.commit("logo", logo);
    },
    pbxElements(context, pbxElements) {
      context.commit("pbxElements", pbxElements);
    },
    pbxTimeSelected(context, pbxTimeSelected) {
      context.commit("pbxTimeSelected", pbxTimeSelected);
    },
    pbxTimeSelectedEnd(context, pbxTimeSelectedEnd) {
      context.commit("pbxTimeSelectedEnd", pbxTimeSelectedEnd);
    },
    buttonSelector(context, buttonSelector) {
      context.commit("buttonSelector", buttonSelector);
    },
    hostName(context, hostName) {
      context.commit("hostName", hostName);
    },
    hostContext(context, hostContext) {
      context.commit("hostContext", hostContext);
    },
    hostTimeZone(context, hostTimeZone) {
      context.commit("hostTimeZone", hostTimeZone);
    },
    dataSet(context, dataSet) {
      context.commit("dataSet", dataSet);
    },
  },
  getters: {
    showBanner: (state) => {
      return state.showBanner;
    },
    user: (state) => {
      return state.user;
    },
    token: (state) => {
      return state.token;
    },
    adminToken: (state) => {
      return state.adminToken;
    },
    avatar: (state) => {
      return state.user;
    },
    account: (state) => {
      return state.account;
    },
    language: (state) => {
      return state.language;
    },
    search: (state) => {
      return state.search;
    },
    logo: (state) => {
      return state.logo;
    },
    pbxElements: (state) => {
      return state.pbxElements;
    },
    pbxTimeSelected: (state) => {
      return state.pbxTimeSelected;
    },
    pbxTimeSelectedEnd: (state) => {
      return state.pbxTimeSelectedEnd;
    },
    buttonSelector: (state) => {
      return state.buttonSelector;
    },
    hostName: (state) => {
      return state.hostName;
    },
    hostContext: (state) => {
      return state.hostContext;
    },
    hostTimeZone: (state) => {
      return state.hostTimeZone;
    },
    dataSet: (state) => {
      return state.dataSet;
    },
  },
});
