{
  "passwordResetMustContain12Characters": "MUST contain at least 12 characters",
  "passwordResetMustContainOneUppercase": "MUST contain at least one uppercase letter",
  "passwordResetMustContainOneSpecialCharacter": "MUST contain at least one special character",
  "passwordResetMustContainOneNumber": "MUST contain at least one number",
  "bannerSandbox": "You are now in the sandbox application.",
  "bannerProduction": "You are now in production.",
  "callQueueNumber": "callqueue number",
  "delete": "Delete",
  "deleteAll": "Delete all",
  "name": "Name",
  "listsName": "list name",
  "upload": "upload",
  "numberOfCalls": "number of calls",
  "numberOfAnsweredCalls": "number of answered calls",
  "callsAnsweredByAnother": "number of calls answered by another",
  "callsTransferredByAgent": "number of calls transferred by agent",
  "abandonedCalls": "number of abandoned calls",
  "callsDeterred": "number of calls deterred",
  "averageWaitingTime": "average waiting time",
  "averageWaitingTimeQueue": "average waiting time in queue",
  "dropoutTime": "average dropout time",
  "averageCallDuration": "average call duration",
  "queueDetail": "queue detail",
  "details": "details",
  "number": "number",
  "didCallers": "DID Callers",
  "groups": "groups",
  "groupsQueues": "groups queues",
  "groupsExtensions": "groups extensions",
  "queueComparison": "Queue comparison",
  "numberOfRepeatedCalls": "number of repeated calls",
  "answeredCallsCoefficient": "answered calls coefficient",
  "abandonedCallsCoefficient": "abandoned calls coefficient",
  "dissuadedCallsCoefficient": "deterred calls coefficient",
  "comparisonSamePeriodLastMonth": "Comparison same period last month",
  "noCallersForThisPeriod": "No callers found for this period",
  "pleaseSelectTwoQueuesForComparison": "Please select two queues for comparison",
  "queueHasNoData": "Selected queue has no data for this period",
  "login": {
    "welcome": "Welcome",
    "newPassword": "New password",
    "forgotPassword": "Forgot password",
    "emailAddress": "Email address",
    "password": "Password",
    "signin": "Sign in"
  },
  "expertStatisticsNavigation": {
    "advancedStatistics": "Advanced Statistics",
    "origins": "Origins",
    "originsTop10": "Origins Top 10",
    "hourlyStatistics": "Hourly Statistics",
    "groups": "Groups",
    "answeredUnansweredCalls": "Answered & Unanswered"
  },
  "pageNotFound": "Sorry, we couldn't find the page you're looking for...",
  "goBackHome": "Go back home",
  "sidebar": {
    "statistics": "Statistics",
    "invoices": "Invoices",
    "tickets": "Tickets",
    "settings": "Settings",
    "subMenu": { "financial": "Financial", "callQueue": "Call Queues" },
    "specialNavigation": {
      "expertService3CX": "Expert Service 3CX",
      "contact": "Smart Routing",
      "georouting": "Geographical Routing",
      "callqueuegroup": "Call Queues Groups",
      "callqueue": "Call Queues"
    }
  },
  "header": {
    "search": "Search",
    "searchTicketPlaceHolder": "Subject, created date or updated date",
    "searchInvoicePlaceHolder": "Invoice, date or total",
    "searchContactPlaceHolder": "Company, name, code or numbers",
    "hello": "Hello",
    "activeAccount": "Active Account",
    "accountName": "Account name",
    "account": "Account",
    "invoices": "Invoices",
    "openTicket": "New Ticket",
    "yourProfile": "Your Profile",
    "settings": "Settings",
    "logout": "Sign out",
    "filter": "Filters",
    "impersonate": "Impersonate",
    "leaveImpersonate": "Leave Impersonate"
  },
  "notificationBanner": {
    "title": "Your application has new features! Open to see more.",
    "learnMoreButton": "Learn more"
  },
  "impersonate": { "popupTitle": "Enter User email to Impersonate" },
  "chartTable": {
    "callType": "Call Type",
    "count": "Count",
    "duration": "Duration",
    "avgPerCall": "Avg per Call",
    "landline": "Landline",
    "mobile": "Mobile",
    "special": "Special",
    "callCount": "Call count",
    "callDuration": "Call duration",
    "fileNumb": "File number",
    "period": "Period",
    "choosePeriod": "Choose period",
    "chooseResource": "Choose resource",
    "resourceNumb": "Resource number",
    "chart": "Charts",
    "data": {
      "incoming": "Incoming",
      "outgoing": "Outgoing",
      "answered": "Answered",
      "unanswered": "Unanswered"
    }
  },
  "dashboards": {
    "title": "Tableau de bord",
    "day": "day",
    "fullDay": "Full day",
    "week": "week",
    "month": "month",
    "concatenate": "Consolidate data",
    "concatenateTitle": "Consolidated data for elements:",
    "chartHeaderTitle": "Statistics for period",
    "searchExtension": "Search extensions",
    "searchQueue": "Search queue",
    "custom": "custom",
    "monthly": "Monthly",
    "daily": "Daily",
    "hourly": "Hourly",
    "monthlyTitle": "Monthly Dashboard of",
    "monthlyDescription": "Get monthly data of",
    "dailyTitle": "Daily Dashboard of",
    "dailyDescription": "Get daily data of",
    "hourlyTitle": "Hourly Dashboard of",
    "hourlyDescription": "Get hourly data of",
    "donutTitle": "Daily Answered & Unanswered Calls Count",
    "stackedColumnCategories": {
      "incomingCount": "Incoming Count",
      "outgoingCount": "Outgoing Count",
      "answeredCount": "Answered Count",
      "unansweredCount": "Unanswered Count",
      "incomingDuration": "Incoming Duration",
      "outgoingDuration": "Outgoing Duration",
      "answeredDuration": "Answered Duration"
    },
    "error": {
      "noDataFoundTitle": "No Data Found",
      "noDataFoundDescription": "The data for this period was not found or don't exist yet, we're sorry for the inconvenience."
    },
    "selectAll": "Select all",
    "clearAll": "Unselect all",
    "clear": "clear"
  },
  "warning": {
    "noDataFoundTitle": "No Data Found",
    "noResultFoundTitle": "No results found",
    "noResultFoundDescription": "Try adjusting your result of filtering otherwise.",
    "noDataFoundDocumentTitle": "No Documents",
    "noDataFoundDocumentDescription": "You don't have any documents. Contact your line manager if it's not supposed to happen.",
    "noDataFoundContactTitle": "No Contacts",
    "noDataFoundContactDescription": "Get started by creating a new contact or by uploading a contacts list.",
    "noDataFoundCtiTitle": "No CTI",
    "noDataFoundCtiDescription": "Get started by creating a new CTI configuration.",
    "noDataFoundRoutingTitle": "No Geographic Routing",
    "noDataFoundRoutingDescription": "Get started by creating a new geographic routing.",
    "noDataFoundCallQueueTitle": "No Callqueues",
    "noDataFoundCallQueueDescription": "Get started by creating a new callqueue.",
    "noDataFoundCallQueueGroupTitle": "No Callqueue Groups",
    "noDataFoundCallQueueGroupDescription": "Get started by creating a new callqueue group.",
    "noDataFoundTimeSlotDescription": "No time slot created yet. Please add one by clicking on 'Add New Time Slot' button.",
    "noDataFoundExceptionDescription": "No time slot created yet. Please add one by clicking on one of the 'Add Exception' buttons.",
    "noDataFoundSMSListTitle": "No SMS Lists",
    "noDataFoundSMSListDescription": "Get started by creating a new SMS list.",
    "noDataFoundElementGroup": "Get started by creating a new group.",
    "noDataFoundDashboardTitle": "No Dashboard",
    "noDataFoundDashboardDescription": "Get started by selected elements and period."
  },
  "pbxExtension": {
    "selectData": "Select Data",
    "selectPeriod": "Select Period"
  },
  "home": {
    "companies": "Companies"
  },
  "ticket": {
    "ticketForm": "Ticket Form",
    "typeDown": "Type down your questions/problems below",
    "category": "Category",
    "subCategory": "Sub Category",
    "selectProblem": "Select Problem",
    "subject": "Subject",
    "status": "status",
    "resolution": "Estimate resolution date",
    "priority": "priority",
    "emailSubject": "Ticket subject",
    "body": "Body",
    "typeAbove": "Type the problem above",
    "send": "Send",
    "cancel": "Cancel",
    "reply": "Reply",
    "writeReply": "Write your reply for the ticket",
    "concerns": "Concerns"
  },
  "invoiceTable": {
    "invoiceId": "Invoice ID",
    "invoiceNumb": "Invoice Number",
    "totalWithoutTax": "Total Without Tax",
    "totalWithTax": "Total With Tax",
    "date": "Date",
    "searchId": "Search ID",
    "searchNumb": "Search number",
    "searchMonth": "MM",
    "searchYear": "YYYY",
    "searchAmount": "Search amount"
  },
  "ticketTable": {
    "subject": "Ticket",
    "status": "Status",
    "priority": "priority",
    "createdDate": "Date",
    "createdAt": "Created at",
    "updatedDate": "Updated",
    "actions": "Actions",
    "reopenTicket": "Reopen",
    "closeTicket": "Close",
    "selectRows": "Select rows",
    "showAll": "Show all",
    "previous": "Previous",
    "next": "Next",
    "rows": "rows",
    "confirmationCloseMsg": "Are you sure you want to close the ticket?",
    "confirmationOpenMsg": "Are you sure you want to reopen the ticket?",
    "closeMsg": "Ticket closed successfully",
    "openMsg": "Ticket opened successfully",
    "successMsg": "Action successful",
    "dialogTitleClose": "Close ticket",
    "dialogTitleOpen": "Open ticket",
    "showing": "Showing",
    "to": "to",
    "of": "of",
    "results": "results",
    "searchStatus": "Search status",
    "searchDate": "DD/MM/YYYY",
    "ticketList": "Tickets List"
  },
  "documentTable": {
    "allDocuments": "All documents",
    "yourDocuments": "Your documents"
  },
  "callqueue": {
    "buttonTitle": "new call queue",
    "buttonTitleGroup": "new call queue group",
    "newFormTitle": "Call queue form",
    "newFormTitleGroup": "Call queue group form",
    "updateException": "Update exception",
    "updateTimeSlot": "Update call queue group",
    "updateFormTitle": "Update call queue",
    "updateFormTitleGroup": "Update call queue group",
    "formSubTitle": "Add new call queue information below.",
    "formSubTitleGroup": "Add new call queue group information below.",
    "subTitle": "Update by completed informations below"
  },
  "callqueueTable": {
    "name": "name",
    "number": "number",
    "weekdayAndDate": "week days or date",
    "weekday": "Week days",
    "specificDate": "Specific date",
    "timeslot": "time slot",
    "exception": "exceptions",
    "reference": "reference",
    "addTimeSlot": "add time slot",
    "addException": "add exception",
    "addExceptionTwelveMonths": "Add bank holidays for 12 next months",
    "formTimeSlotDescription": "Please select a day or day range and the time slots associated",
    "formExceptionDescription": "Please add a callqueue exception by selecting a specific day",
    "formExceptionTwelveMonthsDescription": "Here the next twelve bank holidays for France",
    "selectAllDays": "All week days",
    "inputSelectDays": "Select mutiple days by using",
    "or": "or",
    "day": "day",
    "dateSelected": "Date Selected",
    "daySelected": "Day Selected",
    "start": "start",
    "end": "end",
    "checkStart": "The start can't be between an existed time slot",
    "checkEnd": "The end can't be between an existed time slot",
    "checkBetween": "This timeslot make conflit with existed one",
    "pickedStartBigger": "The start can't be after or the same than the end",
    "newExceptionAdded": "New exception date picked",
    "newExceptionError": "Sorry, there is an error with your selected date",
    "newExceptionExist": "Exception date already exist",
    "selectExceptionError": "Select an exception date in the calendar before to continue",
    "destinationDescription": "You can specify a destination here. The API returns information about the opening or closing of the call queue according to the schedule. Do not specify a destination here if your call flow calls the API later to route the call (geographic or intelligent routing...)",
    "nextFrenchHolidays": "French Bank Holidays over 12 Months",
    "addFrenchHolidaysWarning": "You will automatically add exceptions for all the dates specified above",
    "continue": "Do you wish to continue ?"
  },
  "weekdays": {
    "mon": "monday",
    "tue": "tuesday",
    "wed": "wednesday",
    "thu": "thurday",
    "fri": "friday",
    "sat": "saturday",
    "sun": "sunday"
  },
  "dialog": {
    "confirm": "Confirm",
    "goBack": "Go Back"
  },
  "profile": {
    "userInfo": "User Information",
    "personalDetails": "Personal details",
    "fullName": "Full name",
    "apiToken": "Api Token",
    "email": "Email address",
    "activeAccount": "Active Account",
    "accounts": "Accounts"
  },
  "contacts": {
    "searchAccount": "Search account",
    "searchCompany": "Search company",
    "searchName": "Search name",
    "code": "Code",
    "searchNumber": "Search number",
    "contactAdded": "Contact added successfully",
    "contactDeleted": "Contact deleted successfully",
    "addContact": "new contact",
    "uploadContact": "upload contacts",
    "companyName": "Company",
    "identificationCode": "Client code identification",
    "firstName": "First name",
    "lastName": "Last name",
    "vip": "VIP",
    "onCall": "On-call contact"
  },
  "pbx": {
    "newGroup": "New group",
    "extensionNewGroupForm": "Extension group form",
    "queueNewGroupForm": "Queue group form",
    "extensionFormSubtittle": "Add new extension group information below",
    "queueFormSubtittle": "Add new queue group information below",
    "more": "more elements...",
    "and": "and"
  }
}
