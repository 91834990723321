{
    "extern": "Externe",
    "intern": "Interne",
    "ranges": "Intervalles de temps",
    "declined": "Décliné",
    "pbx host": "Domaine PBX",
    "report detail": "Rapport détaillé",
    "configuration": "configuration",
    "expert statistics configuration": "Configuration experte des statistiques",
    "onlyAdminMsg": "Seul l'administrateur peut effectuer cette action.",
    "yesterday": "Hier",
    "this week": "Cette semaine",
    "last week": "La semaine dernière",
    "this month": "Ce mois-ci",
    "last month": "Le mois dernier",
    "last 3 months": "Les 3 derniers mois",
    "last 6 months": "Les 6 derniers mois",
    "caller": "appelant",
    "redirected to": "redirigé vers",
    "attachments": "Pièces jointes",
    "dissuaded": "Dissuadés",
    "abandoned": "Abandonnés",
    "waiting": "attendant",
    "range": "gamme",
    "beta": "Bêta",
    "timeRange": "Intervalle initial",
    "timeGap": "Intervalles suivants",
    "columns": "Nombre d'intervalles affichés",
    "primaryTextNoAuthPage": "Votre espace client",
    "secondPrimaryTextNoAuthPage": "Une meilleure productivité",
    "paragraphNoAuthPage": "Découvrez les avantages de nos fonctionnalités qui rehaussent votre expérience. Notre plateforme propose different outils conçus pour optimiser votre flux de travail et maximiser votre productivité",
    "ticketingService": "Service support",
    "ticketingServiceDetail": "Profitez d'une experience support grâce à une équipe réactive et compétente, prête à vous assister efficacement dans vos besoins",
    "advancedStatistics": "Statistiques avancées",
    "advancedStatisticsDetail": "Prise de décisions basée sur les données, suivi des performances, prévisions et reporting programmés pour des informations d'appels 3CX optimales",
    "internalReplyContent": "Note interne",
    "declinedSolicitations": "Declined solicitations",
    "declinedCalls": "Declined calls",
    "noDataFoundTitle": "No Data Found",
    "queue_name": "queue name",
    "element_name": "element name",
    "answered_percentage": "Répondus %",
    "talking_duration": "Durée d'appel",
    "is_answered": "Appels répondus",
    "fromTotalCalls": "sur un total de {total} appels",
    "callIdTitle": "Appel #{callId}",
    "voicemailExt": "Boîte vocale",
    "externalNumber": "Numéro externe",
    "waiting_duration": "Durée d'attente",
    "avg_waiting_duration": "Durée d'attente moyenne",
    "avg_talking_duration": "Durée d'appel moyenne",
    "showQueuesOnlyStats": "Ne montrer que les données des files",
    "SMSInformation": "SMS Information",
    "setSMSExpeditorName": "Set SMS Expeditor Name",
    "successMsg": "Action réussie",
    "SMSConfiguration": "SMS Configuration",
    "agentDetails": "Détails de l'agent - {agentNumber} - {agentName} ",
    "copyToClipboard": "Copié dans le presse-papiers",
    "contentCopied": "Contenu copié dans le presse-papiers",
    "CTIConfiguration": "Configuration CTI",
    "callqueueGroup": "Groupe de file d'attente",
    "callqueueGroups": "Groupes de file d'attente",
    "newCti": "Nouveau CTI",
    "ctiDetail": "Détail CTI",
    "newDestination": "Nouvelle destination",
    "ctiToken": "{apiUrl}{'/api/v1/cti/'}{token}{'/%CallerDisplayName%/%CallerNumber%'}",
    "columnsData": "Nombres de colonnes de données",
    "ringing_duration": "Temps de sonnerie",
    "unanswered": "Non Répondus",
    "answered": "Répondus | Répondus sous {seconds} secondes",
    "talking": "Temps de conversation | Temps de conversation sous {seconds} secondes",
    "groupData": "Regrouper les données de la période",
    "overview": "Aperçu général",
    "outgoing": "Appels sortants",
    "durations": "Temps d'attente",
    "waitingTime": "temps d'attente",
    "waitingTimeRange": "Temps d'attente (5 à 30sec):",
    "durationWaitingTime": "Durée de temps d'attente",
    "percentageWaitingTime": "Pourcentage d'appels ayant reçu une réponse dans la plage de temps sélectionnée",
    "avgTalkingDuration": "Durée moyenne de la conversation",
    "avgTalking": "Durée moyenne",
    "avgRingingDuration": "Durée moyenne de sonnerie",
    "avgWaitingTime": "Temps d'attente moyen",
    "avgDurationTotal": "Durée moyenne totale",
    "avgCallDuration": "Durée moyenne des appels",
    "displayAnswerTimeIn": "Afficher le temps de réponse en",
    "showDetailedCallStats": "Afficher statistiques détaillées",
    "expertStatsDurationsInfoText": "Ce graphique montre le pourcentage d'appels pris dans l'intervalle de temps sélectionné - de chaque file d'attente - pour la période sélectionnée.",
    "expertStatsDurationsInfoSubTextOne": "Utilisez le curseur ci-dessous pour sélectionner le temps d'attente moyen - la valeur par défaut est de 20 secondes.",
    "expertStatsDurationsInfoSubTextTwo": "Sélectionnez une file d'attente dans la barre de recherche ci-dessus pour voir les statistiques détaillées du temps d'attente moyen quotidien.",
    "extension": "Extension",
    "newUser": "Nouvel utilisateur",
    "view": "Voir",
    "contactDetailView": "Détail de l'utilisateur - {entity}",
    "contactLists": "Liste des contacts",
    "elementDeletedSuccessfully": "{element} supprimé(e) avec succès",
    "elementCreatedSuccessfully": "{element} creé(e) avec succès",
    "elementUpdatedSuccessfully": "{element} mis à jour avec succès",
    "elementDuplicatedSuccessfully": "{element} dupliqué(e) avec succès",
    "duplicateList": "Dupliquer la liste",
    "miss": "Mademoiselle",
    "mrs": "Madame",
    "mr": "Monsieur",
    "mrandmrs": "Madame et Monsieur",
    "doctor": "Docteur",
    "idCodeFieldRequirement": "Requis quand aucun numéro appelant n'est renseigné.",
    "contact": "Contact",
    "contacts": "Contacts",
    "invoicing": "Factures",
    "documents": "Documents",
    "services": "Services",
    "customer-files": "Dossier clients",
    "ticketing": "Tickets",
    "smart-routings": "Routages intelligents",
    "sms": "Services SMS",
    "customers": "Clients",
    "webradios": "Radios Web",
    "pbx-hosts": "Hôtes PBX",
    "expert-statistics": "Experts statistiques",
    "training": "Formation",
    "civility": "Civilité",
    "information-collections": "Collectes",
    "client.admin": "Administrateur",
    "client.accountant": "Comptabilité",
    "client.technician": "Technicien",
    "allPermission": "Accès total",
    "createPermission": "Ajouter",
    "editPermission": "Modifier",
    "deletePermission": "Supprimer",
    "viewPermission": "Lire",
    "updatePermission": "Mettre à jour",
    "writePermission": "Droit d'écriture",
    "permission.*": "Accès total",
    "permission.create": "Ajouter",
    "permission.edit": "Modifier",
    "permission.delete": "Supprimer",
    "permission.view": "Lire",
    "permission.update": "Mettre à jour",
    "permission.write": "Droit d'écriture",
    "permission.send": "Envoyer",
    "permission.enable": "Activer/Désactiver",
    "permission.view-own": "Lire les siens",
    "permission.edit-own": "Modifier les siens",
    "hasUser": "Un utilisateur est lié à ce contact",
    "hasNoUser": "Aucun utilisateur lié pour le moment, veuillez revenir plus tard",
    "active": "Actif",
    "nonactive": "Désactivé",
    "noProvided": "{type} non fourni(e)",
    "callqueueGroupCreatedOn": "Ce @.lower:callqueueGroup a été créé le {date}",
    "noPermissionsTitle": "Utilisateur en cours de création...",
    "noPermissionsDescription": "Nous sommes en train de créer un compte pour ce contact, merci de patienter quelques secondes...",
    "contactCreatedOn": "Cet @:user a été créé le {date}",
    "profileCreatedOn": "Ce @.lower:header.account a été créé le {date}",
    "myCourses": "my courses",
    "myResults": "my results",
    "myPrograms": "my programs",
    "quizNotCompleted": "Quiz incomplet",
    "smsSserviceTitle": "Services SMS",
    "smsSserviceSubTitle": "Gérez des contacts et envoyez des SMS transactionnels ou commerciaux",
    "smsSserviceCartTitle": "Introduction aux Services SMS",
    "smsSserviceCartDetail": "Améliorez votre communication commerciale avec les services SMS Encom : messagerie simplifiée, gestion de contacts et analyses réunis sur votre espace client. Envoyez des messages individuellement ou en groupe",
    "smsSserviceListTitle": "Services inclus",
    "smsSserviceList1": "Envoyez des SMS transactionnels ou promotionnels",
    "smsSserviceList2": "Gérez et organisez vos contacts et listes",
    "smsSserviceList3": "Ne payez que ce que vous utilisez",
    "smsSserviceList4": "Programmez des campagnes via l'API",
    "smsSserviceFooterTitle": "Activer le service",
    "smsSserviceFooterDetail": "Prêt à débuter ? Contactez votre chargé d'affaires Encom pour activer les services SMS sur votre compte",
    "webRadios": "Web Radios",
    "webRadio": "Radio web",
    "newRadio": "Nouvelle radio",
    "stream": "Flux",
    "duration": "Durée",
    "frequency": "Fréquence",
    "newStream": "Nouveau flux",
    "24hOption": "Option 24h",
    "callQueueAndSviComment": "Pour les cas particuliers non pris en charge par cette collecte 3CX, merci de préciser dans ce champ le fonctionnement attendu en prévision de votre réunion de pré-lancement",
    "24hOptionDescription": "En utilisant l'option 24h, l'élément sera disponible pour {days} toute la journée.",
    "welcome": "Bienvenue",
    "forgotPassword": "Mot de passe oublié",
    "newPasswordCondition": "Choisissez un nouveau mot de passe :",
    "setActiveAccount": "Vous pouvez passer d'un compte à l'autre en sélectionnant un compte dans la liste ci-dessous",
    "password": "Mot de passe",
    "confirmPassword": "Confirmer le mot de passe",
    "passwordMustMatch": "Les mots de passe doivent être identique",
    "passwordLength": "Le mot de passe doit comporter au moins 12 caractères",
    "allPasswordCondition": "Le mot de passe doit comporter au moins un caractère spécial, un chiffre et une lettre majuscule",
    "errorFileIsRequired": "Veuillez importer un fichier si vous souhaitez ajouter un message existant.",
    "invoices": "Factures",
    "nonConfiguredNumbers": "Aboutissement des numéros non configurés",
    "nonConfiguredNumbersDetail": "Dans les rares cas où un appel n'aboutit pas car le numéro n'est pas configuré, choisissez vers quelle destination seront redirigés ces appels",
    "tickets": { "tickets": "Tickets", "open": "Ouverts", "closed": "Fermés" },
    "folder": "Dossiers",
    "settings": "Paramètres",
    "subMenu": { "financial": "Finances", "callQueue": "File d'attente" },
    "specialNavigation": {
     "smartRoutings": "Routages Intelligents",
     "expertService3CX": "Services Experts 3CX",
     "contact": "Contacts",
     "georouting": "Routages Géographiques",
     "callqueuegroup": "Groupes de files d'attente",
     "callqueue": "Files d'attente",
     "reportList": "Rapport"
    },
    "pressDialFor": "Tapez {dial} pour",
    "somethingWentWrong": "Il y a eu un problème",
    "somethingWentWrongWhileImporting": "Il y a eu un problème lors à l'importation du fichier",
    "dialAlreadyUsed": "Ce choix a déjà été utilisé",
    "descAlreadyUsed": "Cette destination a déjà été utilisé",
    "title": "Tableau de bord",
    "day": "Jour",
    "selectRows": "Sélectionner des lignes",
    "rows": "{n} lignes",
    "phoneContactsImported": "{n} numéros associés",
    "fullDay": "Journée entière",
    "week": "Semaine",
    "submitted": "Soumise",
    "readyToBeDeployed": "Prête à être déployée",
    "collectOpen": "Ouverte",
    "concatenate": "Consolider",
    "concatenateTitle": "Données consolidées pour les éléments",
    "chartHeaderTitle": "Statistiques pour la période",
    "searchExtension": "Rechercher extensions",
    "searchQueue": "Rechercher file d'attente",
    "hourly": "Horaire",
    "hourlyTitle": "Tableau de Bord par heure de ",
    "hourlyDescription": "Afficher les données horaires de",
    "invoice": "Facture",
    "georouting": "Routages géographiques",
    "newGeoRouting": "Nouveau routage",
    "geoModel": "Modèle géographique",
    "fallback1": "Destination fallback 1",
    "fallback2": "Destination fallback 2",
    "fallback3": "Destination fallback 3",
    "fallback4": "Destination fallback 4",
    "frenchDepartments": "Départements français",
    "idList": "Liste d'ID (API)",
    "districts": "Régions",
    "noListAdded": "Aucunes listes",
    "routing": "Routage",
    "destinationRoutingListAvailable": "Liste des destinations disponible",
    "geographicalDataModel": "Modèle géographique",
    "geographicalRoutesList": "Liste des routes géographiques",
    "geographicalRoutesAvailable": "Routes géographiques disponible",
    "geographicalDestinationRoutes": "Liste de routages",
    "emptyStateTitle": "Pas de {title}",
    "emptyStateSubtitle": "Pas de données disponibles dans cette rubrique.",
    "customerProvideSwitchYes": "Oui, nous fournirons le switch.",
    "customerProvideSwitchNo": "Non, le switch sera fourni par Encom.",
    "firewallProviderYes": "Oui, nous sous-traitons la maintenance à un prestataire externe.",
    "firewallProviderNo": "Non, la gestion du Firewall est gérée en interne.",
    "smartphoneWifiYes": "Oui, dans nos locaux nous utiliserons principalement nos smartphones pour nous connecter à 3CX.",
    "smartphoneWifiNo": "Non, dans nos locaux nous utiliserons principalement nos téléphones IP et/ou nos ordinateurs pour nous connecter à 3CX.",
    "hotdesking": "Permet à l'usager de se connecter à un poste physique dédié au",
    "hotdeskingDetail": "Un poste Hot Desking est un poste qui est attribué à aucun utilisateur fixe et qui permet à plusieurs utilisateurs nomades de s'y connecter à l'aide d'un code pin",
    "invoicesDetail": "Ce contact doit recevoir les factures mensuelles par email",
    "contactAdminDetail": "Ce contact est administrateur de l’Espace Client Encom",
    "ipPhoneModel": "Associer un poste téléphonique à l’utilisateur",
    "pleaseUseFormat": "Le format doit être du type {n}",
    "errorOnFinalFormFqdn": "Veuillez fournir votre domaine",
    "errorNoNumber": "Veuillez fournir un numéro de téléphone.",
    "errorHasNdi": "Ce numéro est un NDI ajouté dans la section Portabilité.",
    "errorFormNumberPortability": "Veuillez fournir une date de portabilité",
    "errorFormNumberTown": "Veuillez fournir une site pour ce portage",
    "errorFormNumberInside": "Cette sélection crée un conflit avec une sélection existante.",
    "errorFormNumberFromSmallerThanTo": "Le début de la fourchette ne peut pas être plus petit à la fin.",
    "errorFormNumberFromAndToEqual": "Si vous voulez ajouter un seul numéro, utilisez plutôt la section numéros isolés.",
    "emailErrorFormat": "Le format email doit être du type",
    "followFormat": "Veuillez respecter le format",
    "errorIsInside": "Veuillez choisir un nombre dans une fourchette donnée",
    "numbAlreadyAdded": "Ce numéro a déjà été ajouté.",
    "errorCreatedExtensionsLength": "Cette extension doit contenir autant de chiffres que ceux enregistrés précédemment.",
    "forbiddenExtension": "Vous n'êtes pas autorisé à utiliser cette extension.",
    "tooltipOPCO": "Un OPCO est un organisme agréé par l'Etat chargé de financer vos formations. Vous devez obligatoirement sélectionner votre OPCO si votre formation Encom est prise en charge par votre OPCO.",
    "theEmail": "L'email",
    "notificationMandate": "Décidez quelles sont les notifications que recevra par email l’utilisateur",
    "notificationMandate2": "Par défaut, aucunes notifications n'est activées",
    "yesPredecrocheAgent": "L'extension commencera à sonner uniquement lorsque le message de pré-décroché est terminé",
    "noPredecrocheAgent": "L'extension commencera à sonner dès le début de lecture du message de pré-décroché",
    "predecrocheMusicTitle": "Personnaliser la musique/message d'attente",
    "predecrocheMusic": "Musique/message d'attente",
    "goToSection": "Rendez-vous dans la section",
    "toManage": "pour gérer vos",
    "predecrocheMusicDescription": "Rendez-vous dans la section 06 Studio pour créer ou importer votre pré-décroché",
    "predecrocheMusicDefault": "La musique d’attente de 3CX sera jouée par défaut",
    "yes": "oui",
    "maxCallerNumb": "Nombre maximum d'appelant dans la file d'attente",
    "allDestinations": "Toutes les destinations ouvertes",
    "noResponseTime": "Spécifier le délai d’attente de l’appel avant d’être transféré en cas de non réponse (en secondes)",
    "no": "non",
    "cancel": "Annuler",
    "save": "Sauver",
    "fullName": "Nom complet",
    "firstName": "Prénom",
    "lastName": "Nom",
    "outputDisplayNumber": "Présentation du numéro",
    "mobileNumber": "Téléphone mobile",
    "mobilePhone": "Téléphone mobile",
    "landlinePhone": "Téléphone fixe",
    "phoneNumber": "Téléphone fixe",
    "phone": "Téléphone fixe",
    "phoneOrMobile": "Téléphone fixe ou mobile",
    "extensionMandateDetail": "Format de 3 à 5 chiffres (sauf 112 réservé aux urgences)",
    "personalInformation": "Informations sur l'utilisateur",
    "personalInformationDetail": "Informations relatives à l'utilisateur",
    "edit": "Modifier",
    "editItem": "Modifier l'élément",
    "editItemMessage": "Si vous modifiez cet élément, vous risquez de perdre des informations provenant de formulaires déjà remplis. Souhaitez-vous continuer ?",
    "editItemMessageInfo": "Si vous voulez conserver les informations déjà existantes, cliquez sur annuler.",
    "add": "ajouter",
    "unsavedChangesTitle": "Modifications non enregistrées",
    "unsavedChangesMessage": "En cliquant sur « Quitter sans enregistrer » ces modifications seront perdues.",
    "unsavedChangesWarningMessage": "Vous avez commencé à remplir des informations qui n'ont pas été sauvegardées. En cliquant sur « Quitter sans enregistrer » ces modifications seront perdues.",
    "unsavedChangesMessageOption": "Cliquer sur  « Annuler » pour revenir à la Collecte et enregistrer vos nouvelles modifications.",
    "unsavedChangesCancelButton": "Quitter sans enregistrer",
    "savedChangesOkButton": "Enregistrer les modifications",
    "internationalCall": "Appels internationaux",
    "internationalCallDetail": "En raison de l'utilisation dématérialisée de la solution 3CX, par opposition aux anciens systèmes de télécommunications, il est important de prendre en compte le risque d'usurpation d'identité par le vol de vos identifiants et mots de passe 3CX, ce qui permet à un pirate informatique d'émettre des appels depuis votre compte.",
    "internationalCallDetail2": "Ainsi, une fois la solution installée, il est essentiel de supprimer les e-mails de bienvenue reçus dans vos boîtes de réception pour tous les utilisateurs concernés. Cette mesure de sécurité vise à prévenir les tentatives d'accès non autorisées.",
    "internationalCallDetail3": "Pour garantir une sécurité optimale, nous vous demandons également de nous fournir la liste complète des pays que vous appelez régulièrement. Cette liste nous permettra de restreindre les destinations accessibles immédiatement. Si vous avez besoin d'ajouter d'autres pays à l'avenir, vous pourrez ouvrir un ticket pour demander leur activation immédiate.",
    "internationalCallDetail4": "Cette précaution est essentielle afin de vous protéger contre une éventuelle surfacturation en cas de vol de vos identifiants. Notre priorité est de veiller à la sécurité de vos comptes et à la protection de vos données.",
    "internationals": "Sélectionnez les destinations autorisées",
    "restrictionsInternationals": "Restrictions sur appels internationaux",
    "restrictionsMissCalls": "Restrictions sur autres destinations",
    "internationalDetail": "Spécifier les destinations à ouvrir",
    "restrictionsActivated": "Restriction activée : aucun appel international ne pourra être émis",
    "restrictionsDesactivated": "Restriction désactivée : les appels internationaux peuvent être émis dans la limite des destinations ouvertes dans la Section 01",
    "start": "Début",
    "end": "Fin",
    "errorInvalidNumber": "Veuillez utiliser un numéro de téléphone valide (format 0198765432)",
    "unconfiguredUsersOutcome": "Veuillez fournir une destination d'aboutissement",
    "numberIsolated": "Numéros isolés (Ligne analogique, Fax, Numéro VoIP)",
    "bannerSandbox": "sandbox",
    "bannerProduction": "production",
    "requestSent": "Requête envoyée",
    "passwordLinkResetSent": "Vous recevrez un lien pour réinitialiser votre mot de passe.",
    "resetCreatePasswordDetail": "Si un compte existe pour cet e-mail, un lien de réinitialisation de mot de passe vous sera envoyé.",
    "passwordReset": "Réinitialisez le mot de passe",
    "passwordCreate": "Créez votre mot de passe",
    "passwordResetMustContain12Characters": "Le nouveau mot de passe doit contenir au moins 12 caractères",
    "passwordResetMustContainOneUppercase": "Le nouveau mot de passe doit contenir au moins une majuscule",
    "passwordResetMustContainOneSpecialCharacter": "Le nouveau mot de passe doit contenir au moins un spécial caractère",
    "passwordResetMustContainOneNumber": "Le nouveau mot de passe doit contenir au moins un nombre",
    "name": "nom",
    "sda": "Numéros",
    "numberIdentifier": "Indicatif de numéro",
    "portabilityMandate": "Mandat de portabilité",
    "portabilityMandateDescription": "(1) Téléchargez ici le mandat de portabilité à compléter (2) Vérifiez et signez votre mandat (3) Importez votre mandat",
    "portabilityMandateAndInvoice": "Mandat(s) de portabilité et facture(s)",
    "portabilityMandateDetail": "Le mandat doit être aux mêmes raison sociale et adresse que la facture de votre ancien opérateur",
    "portabilityMandateDetail2": "Le mandat doit être aux mêmes raison sociale et adresse que la facture de votre ancien opérateur",
    "telephoneOperatorInvoice": "Facture opérateur téléphonique",
    "telephoneOperatorInvoiceDescription": "Veuillez importer une copie de vos factures opérateurs (moins de 3 mois) sur lesquelles figurent les numéros à porter et les numéros de tête de ligne",
    "telephoneOperatorInvoiceDescription2": "Si vous avez ajouté des numéros non portés, merci présenter une facture prouvant que vous les détenez",
    "sdaCreation": "Création de numéros",
    "sdaCreationDetail": "Si vous souhaitez créer de nouveaux numéros, veuillez indiquer le nombre de numéros à créer et la ville où ils seront utilisés",
    "sdaCreate": "Nombre de numéros à créer",
    "sdaCreateDetail": "par tranche de 5 numéros minimum",
    "sdaLinked": "Numéro(s) associé(s)",
    "sviSdaLinkedError": "Veuillez fournir un ou plusieurs numéro(s) à associer",
    "numberToAssign": "Numéro(s) à attribuer à l’utilisateur",
    "sdaOrderedLeft": "Numéro(s) restants",
    "sdaOrderedLeftDetail": "Indiquez votre numéro demandés en portabilité ou attribuez un nouveau numéro que vous avez commandé",
    "newNumber": "Attribuer un nouveau numéro",
    "associatedTo": "Attribuer à",
    "carriedNumber": "Sélectionner un de vos numéros portés",
    "ownNumber": "Ajouter un numéro externe",
    "internalNumber": "Extension à associer à l’utilisateur",
    "listName": "Nom de liste",
    "callQueue": "Files d'attente",
    "callqueues": "Files d'attente",
    "waitingQueues": "Files d'attente",
    "waitingQueuesDescription": "La file d’attente permet de rediriger un appel entrant vers plusieurs agents. Lorsque tous les agents sont occupés, les appels sont mis en attente jusqu’à ce que l’un d’eux se libère",
    "waitingQueuesDescriptionOld": "Les files d'attente permettent de mettre en attente les appels le temps que les agents (membres d’une file d’attente) puissent répondre aux appels. Les appels ne restent pas sans réponse, ils sont mis en attente. Par exemple, il est possible de mettre en place un groupe de 3 commerciaux, et de faire en sorte que le numéro du service commercial soit routé vers une file d’attente spécifique au département commercial. Si les 3 commerciaux sont occupés, les correspondants seront mis en file d’attente jusqu’à ce qu’un commercial se libère. Ce tableau permet de définir le fonctionnement d'une ou plusieurs files d'attente",
    "queue": "File d'attente",
    "callFlow": "Call flow",
    "all": "Tous",
    "world": "Monde",
    "origin": "Origine - File d'appel",
    "deleteAll": "Tout supprimer",
    "deleteWebradio": "Supprimer le flux {name}",
    "deleteWebradioMessage": "Êtes-vous sûr de vouloir supprimer ce flux ?",
    "deleteContact": "Supprimer l'utilisateur {name}",
    "deleteContactMessage": "Êtes-vous sûr de vouloir supprimer cet utilisateur ?",
    "deleteDestination": "Supprimer la destination d'origine {name}",
    "deleteDestinationMessage": "Êtes-vous sûr de vouloir supprimer cette destination ?",
    "deleteCTIMessage": "Êtes-vous sûr de vouloir supprimer ce CTI ?",
    "deleteAllContacts": "Supprimer tous les contacts",
    "deleteAllCTI": "Supprimer tous les CTI",
    "deleteCTI": "Supprimer le CTI {name}",
    "deleteGeoRouting": "Supprimer le routage {name}",
    "deleteGeoRoutingMessage": "Êtes-vous sûr de vouloir supprimer ce routage intelligent ?",
    "deleteAllContactsDetail": "Attention, vous êtes sur le point de supprimer TOUS les contacts enregistrés. Souhaitez-vous continuer ?",
    "deleteAllCTIDetail": "Attention, vous êtes sur le point de supprimer TOUTES les configurations CTI enregistrés. Souhaitez-vous continuer ?",
    "delete": "Supprimer",
    "callQueueNumber": "numéro de file d'attente",
    "othersNumbersPortabilityList": "Liste de numéros non portés",
    "othersNumbersPortabilityTitle": "Autres numéros non portés",
    "notBundledNumber": "Numéro non porté",
    "othersNumbersPortabilityDetail": "Si vous possédez des numéros que vous ne souhaitez pas porter, mais que vous voulez utiliser en tant que numéro présenté pour une ou plusieurs extensions, vous pouvez les renseigner ici",
    "confirmCollect": "Confirmer et envoyer",
    "missedCalls": "Appels manqués non notifiés/notifiés",
    "missedCallsDetails": "Ils sont envoyés par email a l'adresse renseigne plus haut",
    "numberOfCalls": "Nombre d'appels",
    "beNotified": "Je souhaite être notifié par e-mail",
    "notBeNotified": "Je ne souhaite pas être notifié par e-mail",
    "answeredCalls": "appels répondu",
    "numberOfAnsweredCalls": "Nombre d'appels répondu",
    "mainInfos": "Informations générales",
    "numbersPortability": "Numéros & Portabilités",
    "numbersPortabilityTitle": "Portabilité de vos numéros",
    "numbersPortabilityDetail": "La portabilité des numéros vous permet de conserver vos numéros actuels. Veuillez indiquer les numéros que vous souhaitez porter",
    "rangeNumber": "Tranche de numéros à porter",
    "users": "utilisateurs",
    "usersTitle": "Utilisateurs",
    "usersMandateHeadline": "L'utilisation des extensions 12, 15, 17, 18 et 112 sont reservées aux numéros d'urgence",
    "usersCollectDetail": "Cette section permet de définir pour chaque utilisateur les extensions et les droits qui lui sont associés",
    "siteAndNetwork": "Sites & Réseaux",
    "customerRelations": "Relations clients",
    "legalNotice": "Mentions Légales",
    "endCollect": "Conclusion",
    "legalNoticeDetail": "Merci d'avoir pris le temps de remplir ce document",
    "privacyGdpr": "RGPD",
    "privacyGdprDetail": "Il est obligatoire de valider la connaissance des règlements mis en place par le RGPD",
    "privacyGdprToogleLegend": "En cochant ci-dessous, vous confirmez que vous avez pris connaissance de la réglementation RGPD",
    "privacyGdprComment": "Pour toute information complémentaire sur votre configuration, vous pouvez remplir ce champ",
    "goBackToDependentStep": "Veuillez d'abord compléter l'étape",
    "globalSettings": "Paramètres de l'extension",
    "generalSettings": "Paramètres généraux",
    "globalSettingsDetail": "Informations générales concernant votre 3CX",
    "companyName": "Nom du site",
    "company": "Société",
    "youUploaded": "Vous avez importez",
    "clickToValidateContact": "Votre fichier contient {element} contact(s) valide(s) et peut être importé en cliquant sur",
    "clickToValidateDestination": "Votre fichier contient {element} destination(s) valide(s) et peut être importé en cliquant sur",
    "clickToValidateImportation": "Votre fichier contient {users} utilisateur(s) valide(s) et peut être importé en cliquant sur",
    "youCanAddByClicking": "Vous pouvez les ajouter en cliquant sur le boutton",
    "successfullyUploaded": "Fichier interprété avec succès",
    "userUploadedSuccessfully": "Utilisateurs importés avec succès",
    "selectElementFromList": "Veuillez sélectionner un élément dans la liste",
    "selectQueueFromList": "Veuillez sélectionner une file et une période",
    "below": "plus bas",
    "3cxDomain": "Domaine 3CX",
    "usersList": "Cette section permet d’ajouter les utilisateurs dans le groupe puis de définir pour chaque utilisateur les extensions et les droits qui lui sont associés",
    "yourDomain": "Votre domaine",
    "3cxDomainType": "Adresse URL de connexion à votre domaine 3CX",
    "3cxDomainRoot": "Racine du domaine 3CX",
    "opco": "Votre OPCO",
    "street": "N° et libellé de la voie",
    "postal": "Code Postal",
    "country": "Pays",
    "pleaseCorrect": "Veuillez corriger",
    "callsAnsweredByAnother": "répondu par autres",
    "numberOfCallsAnsweredByAnother": "Nombre d'appels répondu par quelqu'un d'autres",
    "callsTransferredByAgent": "transférés par un agent",
    "numberOfCallsTransferredByAgent": "Nombre d'appels transférés par un agent",
    "abandonedCalls": "appels abandonnés",
    "numberOfAbandonedCalls": "Nombre d'appels abandonnés",
    "callsDeterred": "appels dissuadés",
    "numberOfCallsDeterred": "Nombre d'appels dissuadés",
    "averageWaitingTime": "Temps d'attente moyen",
    "averageWaitingTimeQueue": "Temps d'attente moyen (file d'attente)",
    "dropoutTime": "Temps moyen de décrochage",
    "averageDropoutTime": "Temps moyen d'abandon de l'utilisateur durant l'attente",
    "averageCallDuration": "Durée moyen",
    "queueDetail": "détaillé par file",
    "details": "Détails",
    "solicited": "sollicités",
    "totalWithTax": "Montant TTC",
    "totalWithoutTax": "Montant HT",
    "number": "numéro",
    "numbers": "numéros",
    "close": "fermer",
    "didCallers": "Appels SDA",
    "didReport": "detaille par numéro",
    "pleaseSelectANumberFromTheSelectorAbove": "Please select a number from the selector above",
    "groups": "Groupes",
    "group": "Groupe",
    "deleteGroup": "Supprimer le groupe",
    "callqueueDetailsSettings": "Détails et paramètres de la file d'attente",
    "deleteUser": "Supprimer l'utilisateur",
    "updateCallQueueForm": "Veuillez mettre à jour en complétant les informations ci-dessous",
    "callQueueUpdatedSuccessfully": "File d'attente mis à jour avec succès",
    "callQueueGroupUpdatedSuccessfully": "Groupe de file d'attente mis à jour avec succès",
    "deleteUserFromGroup": "Supprimer {user} du groupe",
    "deleteStudioMessage": "Supprimer le message studio",
    "deleteSvi": "Supprimer le SVI",
    "callQueueDeletedSuccessfully": "File d'attente supprimé avec succès",
    "callQueueGroupDeletedSuccessfully": "Groupe de file d'attente supprimé avec succès",
    "groupDeletedSuccessfully": "Groupe supprimé avec succès",
    "userDeletedSuccessfully": "Utilisateur supprimé avec succès",
    "sviDeletedSuccessfully": "SVI supprimé avec succès",
    "studioMessageDeletedSuccessfully": "Message studio supprimé avec succès",
    "collectSubmittedSuccessfully": "Collecte validé avec succès",
    "groupsQueues": "Groupes de file d'attente",
    "groupsExtensions": "Groupes d'extensions",
    "queueComparison": "Analyse de file d'attente",
    "numberOfRepeatedCalls": "nombre d'appels répétés",
    "answeredCallsCoefficient": "Coefficient d'appels répondu",
    "abandonedCallsCoefficient": "Coefficient d'appels abandonnés",
    "dissuadedCallsCoefficient": "Coefficient d'appels dissuadés",
    "comparisonSamePeriodLastMonth": "Comparer à la période précédente",
    "noCallersForThisPeriod": "Aucun appel trouvé pour cette période",
    "answeredOrUnanswered_seconds": "{key} sous {seconds} secondes",
    "summaryData": "Statistiques globales",
    "callHistory": "Historique des appels",
    "noCallersForThisPeriodDetail": "Veuillez commencer par sélectionner une date, puis un numéro de téléphone dans liste de gauche.",
    "answered_00_30": "Nombre d'appels traités en moins de 30 secondes",
    "answered_31_60": "Nombre d'appels traités entre 30 et 60 secondes",
    "answered_61_90": "Nombre d'appels traités entre 60 et 90 secondes",
    "answered_90+": "Nombre d'appels traités après 90 secondes",
    "averageAnswered_00_30": "Nombre d'appels traités en moins de 30 secondes",
    "averageAnswered_31_60": "Nombre d'appels traités entre 30 et 60 secondes",
    "averageAnswered_61_90": "Nombre d'appels traités entre 60 et 90 secondes",
    "averageAnswered_90+": "Nombre d'appels traités après 90 secondes",
    "qs": "Score qualité",
    "transferred": "transférés",
    "compareTo": "avec",
    "pleaseSelectTwoQueuesForComparison": "Veuillez sélectionner deux files d'attente",
    "comparison": "comparaison",
    "queueHasNoData": "La file d'attente sélectionnée n'a pas de données pour cette période",
    "year": "an",
    "month": "mois",
    "bulkYear": "{count} an | {count} ans",
    "bulkMonth": "{count} mois | {count} mois",
    "bulkWeek": "{count} semaine | {count} semaines",
    "bulkDay": "{count} jour | {count} jours",
    "bulkHour": "{count} heure | {count} heures",
    "bulkMinute": "{count} minute | {count} minutes",
    "bulkSecond": "{count} seconde | {count} secondes",
    "schedule": "programmé",
    "scheduleReportEditDescription": "Voulez-vous configurer un rapport planifié ?",
    "createdAt": "Crée le",
    "created_at": "Date de création",
    "at": "à",
    "mandateIdentification": "Identifiant Mandat",
    "updatedAt": "Dernière mise à jour",
    "updated_at": "Dernière mise à jour",
    "reportDetailTitle": "Information sur le rapport",
    "reportEditTitle": "Information générale",
    "reportDetailTitleDescription": "Détail global et paramètre du rapport",
    "reportEditTitleDescription": "Configuration générale du rapport",
    "pbxHost": "hôte PBX",
    "emailAddress": "Adresse e-mail",
    "emailAddressReportEditDescription": "Gérer les personnes qui reçoivent le rapport",
    "preview": "aperçu",
    "previewReport": "Vous pouvez prévisualisation la présentation en cliquant sur le bouton ci-dessous",
    "daily": "quotidien",
    "weekly": "hebdomadaire",
    "monthly": "mensuel",
    "cronDaily": "Le rapport sera partagé tous les jours, à 04:00.",
    "cronWeekly": "Le rapport sera partagé tous les Lundis, à 04:00.",
    "cronMonthly": "Le rapport sera partagé tous les 1er du mois, à 04:00.",
    "noReportTitle": "Aucun rapport trouvée",
    "noReportDescription": "Commencez par créer un nouveau rapport en allant dans les tableaux de bord",
    "noHostName": "Aucun hôtes disponible",
    "tooManyElements": "Non disponible : il y a trop d'éléments à sélectionner",
    "closed": "Fermés",
    "ticketsClosed": "Tickets fermés",
    "ticketsOpen": "Tickets ouverts",
    "locked": "Verouillé",
    "importedFile": "Fichier importé",
    "routingContacts": "Contacts routage intelligents",
    "callingNumbers": "Numéros appelants",
    "code": "Code",
    "isVip": "VIP",
    "isOnCall": "Contrat d'astreinte",
    "onlyFileAccepterd": "Seuls les fichiers {file} sont acceptés",
    "customImportedFile": "Personnalisé (fichier importé)",
    "collectionsList": "Liste des collectes",
    "onlineCollect": "collectes en ligne",
    "filesList": "Liste des dossiers",
    "lostChanges": "Nouvelles modifications perdues",
    "informationSaved": "Informations sauvegardées",
    "extensionAlreadyUsed": "Cette extension est déjà utilisée.",
    "existingExtension": "Extension existante",
    "groupNameAlreadyUsed": "Ce nom de groupe est déjà utilisé.",
    "files": "Dossiers",
    "file": "Dossier",
    "documentName": "Nom",
    "fileName": "Nom du dossier",
    "fileInformation": "Information de dossier",
    "fileInformationDetail": "Détails du dossier client et des collectes",
    "account": "Client",
    "sviChoice": "SVI à choix",
    "endCallAfterMessage": "Mettre fin à l'appel",
    "endCallAfterMessageNo": "Après la diffusion du message, l'utilisateur aura les choix définis ci-dessous:",
    "endCallAfterMessageYes": "L'appel se termine après la diffusion du message audio.",
    "siteNetworkInformations": "Informations sites et réseaux",
    "siteNetworkInformationsDetail": "Ces informations nous permettent d'intégrer la télephonie IP sur votre réseau informatique. Nous rappellons également les prérequis téchniques nécéssaires pour garantir la qualité de la voix",
    "studioMessage": "Messages & Studio",
    "studioMessageExample": "Exemples de messages de pré-décroché",
    "studioMessageCreateNew": "Would you like to add a new message?",
    "CRMIntegrationQuestion": "Souhaitez-vous intégrer votre CRM/ERP?",
    "CRMIntegrationActivate": "Activer les intégration",
    "CRMIntegrationDesactivate": "Désactiver les intégration",
    "expertServices3cxActivate": "Activer les Services Experts 3CX",
    "expertServices3cxDesactivate": "Désactiver les Services Experts 3CX",
    "smsActivate": "Activer les Services SMS",
    "smsDesactivate": "Désactiver les Services SMS",
    "CustomerRelationModules": "Intégrations 3CX",
    "customerName": "Nom du client",
    "customerAccount": "N° client",
    "CustomerRelationFormInfo": "Avec 3CX vous pouvez bénéficier de l’intégration CRM, ERP ou système de comptabilité avec votre IPBX pour pouvoir passer des appels vers vos contacts d’un simple clic depuis vos applications. De plus, les appels entrants sont automatiquement liés à un compte client qui s’ouvre sur votre écran et tous les appels sont loggés dans votre CRM.",
    "SmartRouting": "Routage intelligent",
    "SmartRoutingDetail": "En reconnaissant le numéro de l’appelant, les routages intelligents permettent de diriger automatiquement et immédiatement chaque appel vers l’interlocuteur adéquat",
    "CustomerSatisfacionSurvey": "Enquête satisfaction client",
    "VoiceRecognition": "Reconnaissance vocale",
    "LiveWebChat": "Web chat live",
    "WebCallBack": "Web call back",
    "PushSMS": "Push SMS",
    "SelectModules": "Select modules",
    "warningTitle": "Attention",
    "login": {
     "welcome": "Bienvenue",
     "backToLogin": "Retour à la page d'accueil",
     "newPassword": "Première connexion ?",
     "forgotPassword": "Mot de passe oublié ?",
     "emailAddress": "Adresse e-mail",
     "password": "Mot de passe",
     "signin": "Se connecter"
    },
    "newNumbers": "New numbers",
    "newNumbersInformationDescription": "Add the recipients numbers of your SMS here, separated by commas",
    "addNewNumbers": "Add new numbers...",
    "addRecipient": "add recipient",
    "tablePreview": "Aperçu de l'importation",
    "contactTablePreviewConfirmationMsg": "File is ready to upload. Would you like to confirm",
    "contactTablePreviewNoProblemsMsg": "No problems found in the file",
    "contactUploadErrorTitle": "The following errors were found in the uploaded file",
    "contactUploadErrorMsg": "The following errors were found in the uploaded file",
    "contactsErrMsg": "Mauvais format de numéro. Aucune lettre ni aucun symbole n'est autorisé. Les numéros doivent être séparés par une virgule.",
    "destErrMsg": "Mauvais format de numéro. Aucune lettre ni aucun symbole n'est autorisé.",
    "editContact": "edit contact",
    "addDestination": "Ajouter une destination",
    "contactForm": "Contact form",
    "contactFormDescription": "Enter new contact information below",
    "importContactMsg": "Votre fichier d'import doit être un fichier Excel, il peut contenir, soit les nouvelles entrées seulement, soit l'ensemble des données. Les contacts existants seront mis à jour avec les nouvelles informations et les nouveaux contacts seront créés.",
    "deleteItem": "Delete item",
    "confirm": "Confirmer",
    "goBack": "Annuler",
    "confirmationMessage": "Êtes-vous sûr de vouloir supprimer l'élément ?",
    "confirmButton": "Proceed",
    "svi": "SVI",
    "studio": "Message studio",
    "network": "Site et réseaux",
    "relations": "Relations clients",
    "extensionBeingUsed": "L'extension est utilisée dans {forms}. Veuillez d'abord la supprimer du formulaire.",
    "apiNotFound": "Application non reconnue",
    "apiNotFoundErrorMsg": "Malheureusement, nous avons des difficultés à reconnaître cette application. Veuillez nous contacter pour que nous puissions résoudre votre problème.",
    "getInTouch": "Contactez-nous",
    "getInTouchSupportMsg": "N'hésitez pas à nous contacter",
    "search": "Rechercher",
    "associateNumber": "Numéro(s) associé(s)",
    "adminExt": "Extension administrateur",
    "participants": "Participants",
    "validate": "valider",
    "nextStep": "Sauver et continuer",
    "wishToContinue": "Voulez-vous continuer ?",
    "deleteCallQueue": "Supprimer la file d'attente {name}",
    "deleteCallQueueGroup": "Supprimer le groupe de file d'attente {name}",
    "selectAll": "Tout sélectionner",
    "openingDaysAndTime": "Jours et horaires d'ouverture",
    "strategy": "Stratégie de distribution des appels",
    "predecroche": "Prédécroché",
    "reopenTicket": "Réouvrir le ticket",
    "closeTicket": "Clôturer le ticket",
    "thankYouForYourFeedback": "Merci pour votre avis. Cela signifie beaucoup pour nous 👏",
    "subject": "Sujet",
    "signedInAs": "Connecté en tant que",
    "yourFeedback": "Aidez-nous à améliorer votre espace client !",
    "sendYourFeedback": "Envoyez votre feedback",
    "sendYourFeedbackDescription": "Toutes vos remarques et suggestions nous aident à améliorer votre espace client.",
    "valueOutOfFive": "{numb} sur 5",
    "predecrocheAgent": "predecroche agent",
    "to": "à",
    "status": "Statut",
    "Received": "Reçu",
    "sentToApi": "Envoyé avec l'API",
    "statistics": "Statistiques",
    "exception": "exception",
    "timeSlotAddedSuccessfully": "Plage horaire ajoutée avec succès",
    "timeSlotUpdatedSuccessfully": "Plage horaire modifiée avec succès",
    "exceptionAddedSuccessfully": "Exception ajoutée avec succès",
    "exceptionUpdatedSuccessfully": "Exception modifiée avec succès",
    "timeSlot": "Plage horaire",
    "timeSlotDeletedSuccessfully": "Plage horaire supprimée avec succès",
    "deleteTimeSlot": "Supprimer la plage horaire pour {type}",
    "deleteException": "Supprimer l'exception pour {type}",
    "createAndUnlink": "Créer et dissocier",
    "destination": "Destination",
    "exceptionDeletedSuccessfully": "Exception supprimée avec succès",
    "unlinkCallQueue": "Délier la file d'attente",
    "unlinkCallQueueTitle": "Êtes-vous sûr de vouloir créer une {type} pour cette file d'attente spécifique ? En faisant cela, vous dissocierez automatiquement cette file d'attente avec le groupe qui lui est associé",
    "destNoResp": "Destination en cas de non-réponses",
    "adminNotifications": "admin notifications",
    "isComplete": "is complete",
    "marketing": "Marketing",
    "campaignReady": "Campagne prête",
    "campaignInformation": "Information de la campagne",
    "campaignDetail": "Détails et statistiques",
    "campaignLists": "Liste des campagnes",
    "messageLists": "Liste des messages",
    "messageInformation": "Information du message",
    "messageDetail": "Détails et statistiques",
    "completed": "Complété",
    "provideUnconfiguredUsers": "Veuillez fournir une extension d'aboutissement",
    "toBeCompleted": "à compléter",
    "wrongRioFormat": "Le RIO n'est pas au bon format",
    "provideCorrectNumberOrRio": "Veuillez fournir le bon numéro de téléphone et/ou le bon RIO associé",
    "custom": "personnalisée",
    "custom1": "personnalisé",
    "rioMandatoryWarning": "Le RIO n'est pas obligatoire ici mais est fortement recommandé car nous vous le demanderons dans la plupart des cas",
    "cantBeEmpty": "doit être complété",
    "expeditorName": "Nom de l'expéditeur",
    "expeditorNameDescription": "Le nom affiché pour le destinataire lors de la réception du SMS",
    "newOrganisation": "Nouvelle organisation",
    "newOrganisationDescription": "Veuillez créer une nouvelle organisation en remplissant le formulaire ci-dessous",
    "smsType": "Type de SMS",
    "callQueueOrder": "Ordre de distribution",
    "callQueueOrderDescription": "Utilisez les flèches ou faites glisser pour sélectionner l'ordre de distribution dans la file d'attente",
    "disactivate": "désactiver",
    "signin": "Se connecter",
    "deleteMessageTitle": "Supprimer cet élément",
    "deleteMessage": "Si vous supprimez cet élément, vous risquez de perdre les informations des formulaires déjà remplis. Souhaitez-vous continuer ?",
    "show": "Afficher",
    "continue": "Continuer",
    "showMore": "Afficher tout",
    "showLess": "Afficher moins",
    "ownedBy": "Détenu par",
    "userProfile": "Profil",
    "ownerEmail": "Email du propriétaire",
    "otherInformation": "Autres informations",
    "Strategie": "Stratégie",
    "SVI": "SVI",
    "Expert Statistics": "Expert Statistics",
    "SMS": "SMS",
    "Documents": "Documents",
    "otherInformationDescription": "Vous pouvez ajouter ce destinataire à une liste",
    "organisationInformation": "Information de l'organisation",
    "organisationInformationDescription": "Organisation à laquelle vous êtes lié et les détails à ce sujet",
    "smsTypeDescription": "Sélectionnez le type de SMS",
    "smsMessageDescription": "Saisissez le contenu du SMS",
    "marketingDisclaimerPart1": "Attention ! Pour tous les SMS marketing, le message doit finir par",
    "marketingDisclaimerPart2": "C'est obligatoire afin de fournir à vos destinataires un moyen de se désabonner et de respecter les obligations de la CNIL.",
    "campaignNameDescription": "Saisissez le nom de votre campagne",
    "recipientInformation": "Informations sur le destinataire",
    "recipientInformationNewDescription": "Ajoutez les informations du nouveau destinataire ici",
    "recipientListInformationNewDescription": "Ajoutez les informations de la nouvelle liste ici",
    "recipientInformationDescription": "Ajoutez ici les destinataires de votre SMS. Vous pouvez également combiner cette fonction avec une liste de destinataires",
    "signinToAccount": "Connexion à votre espace client",
    "answeredUnansweredCallsWeekDay": "Vue par jour de la semaine",
    "answeredUnansweredCallsWeekYear": "Vue par semaine",
    "expertStatisticsNavigation": {
     "thirdPartyNumber": "Numéro de tiers",
     "thirdPartyNumbers": "Numéro de tiers",
     "advancedStatistics": "Statistiques détaillées",
     "origins": "Origines",
     "origins_title": "Origines en nombre d'appels entrants",
     "originsTop10": "Origines Top 10",
     "hourlyStatistics": "Statistiques horaires",
     "groups": "Groupes",
     "answeredUnansweredCalls": "Répondus & non répondus",
     "answeredUnansweredCallsDaily": "Répondus & non répondus (jour)",
     "reportList": "Rapport",
     "callerNumbers": "Historique des appels",
     "overview": "Aperçu général",
     "durations": "Temps d'attente",
     "pbx": "PBX",
     "trunk": "Trunk",
     "answeredUnansweredCallsWeekDay": "-- Vue par jour de la semaine",
     "answeredUnansweredCallsWeekYear": "-- Vue par semaine"
    },
    "pageNotFound": "Désolé, nous n'avons pas trouvé la page que vous recherchez...",
    "goBackHome": "Retour à l'accueil",
    "sidebar": {
     "statistics": "Tableaux de Bord",
     "invoices": "Factures",
     "tickets": { "tickets": "Tickets", "open": "Ouverts", "closed": "Fermés" },
     "folder": "Dossiers",
     "collects": "Collectes",
     "settings": "Paramètres",
     "subMenu": { "financial": "Finances", "callQueue": "File d'attente" },
     "specialNavigation": {
      "smartRoutings": "Routages",
      "expertService3CX": "Services Experts 3CX",
      "contact": "Contacts",
      "georouting": "Routages Géographiques",
      "callqueuegroup": "Groupes de files d'attente",
      "callqueue": "Files d'attente",
      "reportList": "Rapport"
     }
    },
    "newPassword": "Nouveau mot de passe",
    "newPasswordDescription": "Le mot de passe doit contenir au moins une majuscule, une minuscule et un chiffre.",
    "updateUser": "Modifier l'utilisateur",
    "updateOrganisation": "Modifier l'organisation",
    "updateUserDescription": "Entrez les nouvelles informations ci-dessous",
    "updateCampaign": "Modifier la campagne de SMS",
    "billing": "facturation",
    "billingMode": "Moyen de facturation",
    "content": "Contenu",
    "ready": "Prêt",
    "noMoreData": "Il n'y a plus d'élements à afficher 🚀",
    "noNumber": "Aucun numéro",
    "noCode": "Aucun code",
    "newCampaign": "nouvelle campagne",
    "newMessage": "nouveau message",
    "newRecipient": "nouveau destinataire",
    "newAccount": "nouveau compte",
    "newAccountDescription": "Veuillez créer un nouveau compte en remplissant le formulaire ci-dessous",
    "newList": "nouvelle liste",
    "newSitePlaceholder": "Numéro d'extension de file d'appel, utilisateur, SVI...",
    "updatedDate": "Dernière mise à jour",
    "campaigns": "campagnes",
    "recipientsLists": "Listes de destinataires",
    "recipient": "destinataire",
    "recipients": "Destinataires",
    "list": "liste",
    "user": "utilisateur",
    "userMessage": "Utilisateur",
    "accountDescription": "Choisissez le compte pour cette campagne",
    "accounts": "comptes",
    "goodMorning": "bonjour",
    "goodAfternoon": "bonne journée",
    "goodEvening": "bonne soirée",
    "accepted": "accepté",
    "contactDevTeam": "Contactez l'équipe Dev",
    "unauthorizedAction": "Non autorisé",
    "notAuthorized": "Désolé, vous n'êtes pas autorisé à effectuer cette action.",
    "addGroups": "Ajouter de nouveaux groupes",
    "addGroupsDetail": "Vous n'avez pas encore ajouté de groupe d'extensions à votre collecte. Vous pouvez commencer maintenant et gérer leurs permissions",
    "autoCallback": "Rappel automatique",
    "autoCallbackDescription": "Choisissez comment proposer le rappel automatique",
    "autoCallbackDescriptionYes": "Nécessite un prédécroché informant le client qu'il peut demandé un callback en tapant la touche 2 sur son clavier à tout moment lors de l'attente",
    "autoCallbackDescriptionNo": "Option désactivée",
    "autoCallbackTypeDetail": "Indiquer la tempo en secondes. La durée de la tempo inclut le prédécroché, par exemple si le prédécroché dure 30 sec et que la tempo de rappel auto est à 45 sec, le client aura l'impression qu'on lui propose le rappel auto seulement apres 15 sec d'attente. Vous pouvez nous fournir un message studio pour l'annonce du rappel Auto ou utiliser le message par défaut 3CX",
    "associatedQueue": "File d'attente(s) associée(s) au message",
    "associatedExtension": "Extension(s) associée(s) au message",
    "associatedNumber": "Numéro(s) associé(s) au message",
    "associatedMessage": "associé(s) au message",
    "notRecord": "Fonction « Touche 3 = ne pas être enregistré »",
    "notRecordDescription": "Si vous enregistrez les appels entants, vous pouvez proposer aux appellants de ne pas enregistrer l'appel",
    "notRecordYes": "Le message de prédécroché doit préciser que l'appelant a la possibilité de ne pas être enregistré en composant la touche 3 de son téléphone",
    "notRecordNo": "Le message de prédécroché doit préciser que l'appelant a la possibilité de demander à son interlocuteur de ne pas enregistrer la conversation",
    "import": "importer",
    "export": "Exporter",
    "importFile": "Importer un fichier",
    "importUserWarning": "L’importation d’un fichier supprime et remplace tous les utilisateurs créés auparavant",
    "importFileDetail": "Vous avez la possibilité d'importer un fichier contenant l'intégralité de votre collecte utilisateurs",
    "importFileDetail2": "le format du fichier à compléter puis importer",
    "download": "Télécharger",
    "downloadInvoice": "Télécharger la facture",
    "downloadConsumption": "Télécharger le relevé de consommations (format {type})",
    "here": "ici",
    "selectCategory": "Sélectionner une catégorie",
    "attentionNeeded": "Attention requise",
    "or": "ou",
    "dragAndDrop": "glisser-déposer",
    "documentFormat": "Format {type}",
    "documentNotFound": "Document non trouvé",
    "documentImportedSuccessfully": "Document importé avec succès",
    "noFileHasBeenUploaded": "Aucun fichier n'a été importé",
    "upload": "Importer",
    "welcomeMessage": "message d'accueil",
    "uploadedBy": "Importer par",
    "uploadedAt": "Importer le",
    "andNumberMoreElements": "et {numb} autres sélections...",
    "uploadAFile": "Importer un fichier",
    "none": "Aucun",
    "goBackCollectList": "Retour à la liste des collectes",
    "refresh": "Actualiser",
    "collectCompleted": "Collecte complétée",
    "collectCompletedAt": "Collecte complétée à {numb}%",
    "accessToCollect": "Accéder à la collection {name}",
    "goToSee": "Accéder",
    "hideAnnouncement": "Masquez l'annonce",
    "collectCompletedDescription": "Cette collecte a été soumise à notre équipe. Nous reviendrons vers vous après relecture.",
    "collectReadyDescription": "Cette collecte est prête à être déployée, vous n'y avez donc plus accès.",
    "collectLocked": "Collecte verrouillée",
    "collectLockedDescription": "La collecte est en cours de modifications par un autre utilisateur.",
    "collectLockedAt": "Verouillé le {date}",
    "collectSubmittedAt": "Envoyée le {date}",
    "adminQueueExtension": "Administrateur(s) de la file d’attente",
    "userQueueExtension": "Membres de la file d’attente",
    "strategyOpeningHours": "Stratégie heures d’ouverture",
    "strategyClosingHours": "Stratégie heures de fermeture",
    "members": "Membres",
    "address": "Adresse(s)",
    "advancedSettings": "Paramètres avancés",
    "nonWorkingTimeDestination": "Destination aux heures de fermetures",
    "workingTimeDestination": "Destination aux heures d'ouverture",
    "nonAnsweringDestination": "Destination en cas de non-réponses",
    "audioMessageNumber": "Message d’accueil du SVI",
    "activated": "activé",
    "prehook": "Pré-décroché",
    "announcementMessage": "Message d’annonce",
    "prehookMessage": "Message de pré-décroché",
    "prehookMessageDescription": "A tout moment lors de l’attente, l’appelant active le rappel en tapant la touche 2. Cette option nécessite la mise en place d’un message de pré-decroché (voir ci-dessus Stratégie heure d’ouverture) qui informe l’appelant de cette possibilité",
    "delayTime": "Delai d'attente",
    "delayTimeDescription": "Le rappel est proposé automatiquement après un délai d’attente que vous spécifiez en secondes",
    "messageName": "Nom du message",
    "prehookDescription": "Rendez-vous dans la section 06 Studio pour créer ou importer votre pré-décroché",
    "prehookBeforeCallAgent": "Quand souhaitez-vous que l’extension de l’agent commence à sonner ?",
    "strategyType": "Stratégie de distribution des appels",
    "strategyTypePopup": "Sélectionnez la stratégie de distribution des appels aux agents puis indiquez à droite le délai en secondes durant lequel un agent sonne avant que l'appel ne soit basculé sur l'agent suivant (1 sonnerie correspond à 5 secondes)",
    "switchingTimes": "Délais de bascule en secondes",
    "activateRecording": "Activer l'enregistrement",
    "activateRecordingDescription": "Tous les appels externes (entrants et sortants) sont automatiquement enregistrés",
    "desactivated": "Désactivé",
    "desactivateRecording": "Désactiver l’enregistrement",
    "desactivateRecordingDescription": "Aucun appel n'est enregistré",
    "afterMessagePredecroche": "L’extension de l’agent commence à sonner une fois que le message de prédécroché est terminé",
    "withMessagePredecroche": "L’extension de l’agent commence à sonner dès le début de lecture du message de prédécroché",
    "manual": "Manuel",
    "manualRecording": "Activer l’enregistrement manuellement",
    "manualRecordingDescription": "L’enregistrement des appels est déclenché manuellement par l'agent",
    "addUser": "Ajouter un utilisateur",
    "addressDetail": "Ajouter une ou plusieurs adresses géographiques",
    "availableFromVersion": "Disponible à partir de la version",
   
    "contactDetail": "Ajoutez et précisez les contacts et notamment",
    "contactDetailList1": "Le contact technique pour le déploiement de 3CX",
    "contactDetailList2": "Le contact comptabilité pour la réception et le traitement des factures",
    "signatory": "Signataire",
    "legalRepresentative": "Représentant légal",
    "isSignerContract": "Signataire du contrat",
    "collectContact": "Contact collecte",
    "isSignerIban": "Signataire de l'IBAN",
    "comment": "Commentaire",
    "addYourComment": "Ajoutez votre commentaire",
    "choice": "choix",
    "lastModification": "Dernière modification le",
    "ticketReplies": "{numb} réponse(s)",
    "closedDate": "Date de clôture",
    "ticketClosed": "Ticket clôturé",
    "ticketOpen": "Ticket ouvert",
    "activityFeed": "Flux d'activité",
    "ticketOpenedBy": "{id} ouvert par",
    "openedBy": "ouvert par",
    "noMessage": "Aucun message n'a encore été posté",
    "musicChoice": "Choix de la musique",
    "music": "musique",
    "withMusic": "Avec musique",
    "withoutMusic": "Sans musique",
    "voiceChoice": "Choix de la voix",
    "voice": "voix",
    "typeYourMessage": "Veuillez saisir ici le texte de votre message",
    "yourMessage": "Votre message",
    "useMessage": "utiliser ce message",
    "phonetic": "Précisions phonétiques des mots sujets à interprétation",
    "calls": "Appels",
    "newQueue": "Nouveau Groupe",
    "callQueueName": "Nom de la file",
    "recording": "Enregistrement des appels",
    "recordingDisclaimer": "L’enregistrement des appels est activé sous réserve de conformité au RGPD. Un prédécroché du type",
    "isMandatory": "est obligatoire",
    "recordingDisclaimerSentence": "Votre conversation est susceptible d'être écoutée ou enregistrée",
    "recordingDisclaimerList1": "Veuillez indiquez la durée de stockage des messages dans la section commentaire",
    "recordingDisclaimerList2": "La souscription à une option de stockage supérieur à 1 mois fait l'objet d'une option payante et doit figurer sur le bon de commande",
    "callsRestrictions": "Restriction sur autres destinations activée : indiquez dans la section commentaire les autres restrictions pour chaque utilisateur ou groupe d'utilisateur",
    "noCallsRestrictions": "Restriction sur autres destinations desactivée",
    "signatoryContact": "Signataire",
    "ivsDescriptionInfo": "Exemple : Pour être mis en relation avec le service commercial, composez le 1, pour être mis en relation avec le service technique composer le 2, etc.",
    "ivsDescription": "La fonction SVI (Serveur Vocal Interactif) de 3CX permet de répondre à des appels téléphoniques automatiquement et de proposer un menu d’options aux appelants. Par exemple, “Pour le service commercial tapez 1, pour le support tapez 2 ou patientez pour être transféré à un opérateur”",
    "ivsList": "Liste de SVI",
    "ivsName": "Nom du SVI",
    "messageNameInvalid": "Ce nom est déjà utilisé",
    "technical": "Technique",
    "technicalContact": "Contact technique",
    "accounting": "Comptable",
    "accountingContact": "Contact comptabilité",
    "portabilityDate": "Date de portabilité souhaité",
    "physicalSite": "Sites physiques",
    "physicalSiteName": "Nom du site physique",
    "trainings": "Formations 3CX prise en charge par votre OPCO",
    "trainingMandateHeadline": "Si vous bénéficiez d’une formation 3CX prise en charge par votre OPCO, veuillez indiquer si l’utilisateur participera à une formation Utilisateur et/ou Administrateur",
    "trainingUser": "Formation Utilisateur",
    "trainingAdmin": "Formation Admin",
    "thereWere": "Il y a eu",
    "thereIs": "Il y a",
    "oneOrMore": "une ou plusieurs",
    "entityIncorrectorNotFound": "{entity} incorrect ou introuvable | {entity} incorrecte ou introuvable",
    "errorWithYourSubmission": "erreur(s) avec votre demande",
    "errorImportUsers": "Il y a eu {number} erreur(s) avec votre demande. Veuillez corriger cela, puis re-importer le fichier",
    "errorLogin": "Addresse e-mail ou mot de passe invalide",
    "error500": "Service momentanément indisponible",
    "errorExpertStats": "Une maintenance est en cours sur le service Expert Statistics.",
    "error500Msg": "Malheureusement, nous rencontrons des difficultés sur application. Veuillez nous contacter si le problème persiste.",
    "errors": "erreurs",
    "enableServiceTitle": "Le service {service} n'est pas activé",
    "enableServiceText": "Bénéficiez de ce service en souscrivant auprès de votre contact commercial",
    "needAtLeastOneContact": "Nécessite d'au moins un contact",
    "needAtLeastOneAddress": "Nécessite d'au moins une adresse",
    "miscellaneous": "divers",
    "webClient": "Client Web",
    "webClientDetail": "Permet à l'utilisateur de se connecter à l'interface de gestion 3CX via une page web",
    "city": "Ville",
    "open": "Ouvert",
    "inProgress": "En cours",
    "openTimeSlot": "Jours et horaires d'ouverture",
    "openTimeSlotDetail": "Cliquez pour ajouter les jours et heures d'ouverture",
    "app.state": "État",
    "state": "Région",
    "inactive": "Inactif",
    "intercallDelay": "Temps de pause entre la réception de 2 appels",
    "custom2": "personnalisé",
    "noCustom": "Non personnalisé",
    "intercallDelayDescription": "Personnalisé: indiquez le temps de pause (secondes)",
    "priorityQueue": "File prioritaire",
    "priorityQueueDescription": "Dans le cas ou des agents appartiennent à plusieurs files, les appels de la file prioritaire seront distribués en priorité aux agents",
    "voicemail": "Messages vocaux non envoyés/envoyés",
    "voiceMailbox": "Boîte vocale",
    "voicemailBeNotified": "Messages vocaux envoyés",
    "voicemailNotToBeNotified": "Messages vocaux non envoyés",
    "missedCallsBeNotified": "Appels manqués notifiés",
    "missedCallsNotToBeNotified": "Appels manqués non notifiés",
    "voicemailDetail": "Notification par email avec fichier audio en pièce jointe",
    "restrictionsDetail": "Décidez quelles sont les destinations que l’utilisateur ne peut pas appeler",
    "header": {
     "search": "Rechercher",
     "searchTicketPlaceHolder": "Sujets, dates de création ou dates de mise à jour",
     "searchInvoicePlaceHolder": "Factures, dates ou totaux",
     "hello": "Salut",
     "activeAccount": "Compte actif",
     "accountName": "Nom du Compte",
     "account": "Compte",
     "invoices": "Factures",
     "impersonate": "Mode Imitation",
     "leaveImpersonate": "Quitter Mode Imitation",
     "openTicket": "Nouveau ticket",
     "yourProfile": "Votre profil",
     "settings": "Paramètres",
     "logout": "Déconnexion",
     "yourFeedback": "Donnez votre avis",
     "feedback": "Avis",
     "filter": "Filtrer par statut"
    },
    "notificationBanner": {
     "title": "Votre application a de nouvelles fonctionnalités ! Venez en découvrir plus.",
     "learnMoreButton": "En savoir plus"
    },
    "impersonate": {
     "popupTitle": "Sélectionner l'adresse e-mail d'un Utilisateur pour passer en mode Imitation"
    },
    "chartTable": {
     "callType": "Destination",
     "count": "Nombre d'appels",
     "duration": "Durée",
     "avgPerCall": "Durée moyenne",
     "landline": "Ligne fixe",
     "mobile": "Mobile",
     "special": "Spéciale",
     "callCount": "Nombre d'appels",
     "callDuration": "Durée d'appels",
     "fileNumb": "Numéro de document",
     "period": "Période",
     "choosePeriod": "Choisir la période",
     "chooseResource": "Choisir la ressource",
     "resourceNumb": "Numéro de ressource",
     "chart": "Graphiques",
     "data": {
      "incoming": "Entrant",
      "outgoing": "Sortant",
      "answered": "Répondu",
      "unanswered": "Non répondu"
     }
    },
    "dashboards": {
     "title": "Tableau de bord",
     "day": "jour",
     "fullDay": "Journée entière",
     "week": "Semaine",
     "month": "Mois",
     "concatenate": "Consolider",
     "concatenateTitle": "Données consolidées pour les éléments",
     "chartHeaderTitle": "Statistiques pour la période",
     "searchExtension": "Rechercher extensions",
     "searchQueue": "Rechercher file d'attente",
     "custom": "Personnalisée",
     "hourly": "Horaire",
     "hourlyTitle": "Tableau de Bord par heure de ",
     "hourlyDescription": "Afficher les données horaires de",
     "daily": "Journalier",
     "dailyTitle": "Tableau de Bord Journalier de",
     "dailyDescription": "Afficher les données journalières de",
     "weekly": "Hebdomadaire",
     "monthly": "Mensuel",
     "monthlyTitle": "Tableau de Bord Mensuel de",
     "monthlyDescription": "Afficher les données mensuelles de",
     "donutTitle": "Nombre d'appels quotidien répondu et non répondu",
     "stackedColumnCategories": {
      "incomingCount": "Nombre d'appels entrant",
      "outgoingCount": "Nombre d'appels sortant",
      "answeredCount": "Nombre d'appels répondu",
      "unansweredCount": "Nombre d'appels non répondu",
      "incomingDuration": "Durée d'appels Rentrant",
      "outgoingDuration": "Durée d'appels Sortant",
      "answeredDuration": "Durée d'appels Répondu"
     },
     "selectAll": "Tout sélectionner",
     "clearAll": "Tout désélectionner",
     "clear": "réinitialiser",
     "error": {
      "noDataFoundTitle": "Aucune donnée trouvée",
      "noDataFoundDescription": "Les données pour cette période n'ont pas été trouvées ou n'existent pas encore, nous sommes désolés pour ce désagrément."
     }
    },
    "warning": {
     "noDataFoundTitle": "Aucune donnée trouvée",
     "noResultFoundTitle": "Aucun resultat trouvé",
     "noResultFoundDescription": "Essayez d'affiner votre recherche ou filtrer autrement.",
     "noDataFoundDocumentTitle": "Aucun Documents",
     "noDataFoundFilesTitle": "Aucun Files",
     "noDataFoundDocumentDescription": "Vous ne disposez d'aucun document. Contactez votre supérieur hiérarchique si cela n'est pas censé se produire.",
     "noDataFoundFilesDescription": "Vous ne disposez d'aucun file. Contactez votre supérieur hiérarchique si cela n'est pas censé se produire.",
     "noDataFoundContactTitle": "Aucun Contacts",
     "noDataFoundContactDescription": "Commencez par créer un nouveau contact ou importez une liste de contact.",
     "noDataFoundCtiTitle": "Aucun CTI",
     "noDataFoundCtiDescription": "Commencez par créer une nouvelle configuration CTI.",
     "noDataFoundRoutingTitle": "Aucune Routages Géographiques",
     "noDataFoundRoutingDescription": "Commencez par créer un nouveau routages géographiques.",
     "noDataFoundCallQueueTitle": "Aucune file d'attente",
     "noDataFoundCallQueueDescription": "Commencez par créer une nouvelle file d'attente.",
     "noDataFoundCallQueueGroupTitle": "Aucun groupe de file d'attente",
     "noDataFoundCallQueueGroupDescription": "Commencez par créer un nouveau groupe de file d'attente.",
     "noDataFoundTimeSlotDescription": "Aucun créneau horaire n'a encore été créé. Veuillez en ajouter un en cliquant sur le bouton 'Nouvelle Plage Horaire'.",
     "noDataFoundExceptionDescription": "Aucun jours exceptionnels n'a encore été créé. Veuillez en ajouter un en cliquant sur l'un des boutons 'Nouveau Jour Exceptionnel'.",
     "noDataFoundSMSListTitle": "Aucune listes de SMS",
     "noDataFoundSMSListDescription": "Commencez par créer une nouvelle liste de SMS.",
     "noDataFoundDashboardTitle": "Aucun tableau de bord",
     "noDataFoundElementGroup": "Commencez par créer un nouveau groupe.",
     "noDataFoundDashboardDescription": "Commencez par sélectionner des éléments et une période de temps.",
     "mandate": "Si votre numéro est lié à un abonnement internet, la portabilité du numéro entrainera la résiliation automatique de cet abonnement internet et de la ligne support analogique associée. Il est donc important de vérifier qu'aucun service ne dépende de cette ligne analogique : terminal de paiement, alarme, fax, etc"
    },
    "pbxExtension": {
     "selectData": "Sélectionner une données",
     "selectPeriod": "Sélectionner une période"
    },
    "home": {
     "companies": "Entreprises"
    },
    "ticket": {
     "ticketForm": "Formulaire de création de ticket",
     "typeDown": "Selectionner votre question/problème ci-dessous",
     "category": "Catégorie",
     "subCategory": "Sous-catégorie",
     "selectProblem": "Sélectionnez le problème",
     "subject": "Sujet",
     "emailSubject": "Sujet du ticket",
     "resolution": "Résolution estimée",
     "body": "Body",
     "typeAbove": "Rédiger votre problème ci-dessus",
     "send": "Envoyer",
     "cancel": "Annuler",
     "reply": "Réponse",
     "writeReply": "Écrivez votre réponse pour le ticket",
     "concerns": "Concerns"
    },
    "invoiceTable": {
     "invoiceId": "Facturer ID",
     "invoiceNumb": "Facture",
     "totalWithoutTax": "Montant HT",
     "totalWithTax": "Montant TTC",
     "date": "Date",
     "searchId": "Recherche ID",
     "searchNumb": "Recherche numéro",
     "searchMonth": "MM",
     "searchYear": "AAAA",
     "searchAmount": "Recherche montant"
    },
    "ticketTable": {
     "subject": "Ticket",
     "status": "Statut",
     "priority": "Priorité",
     "createdDate": "Date",
     "createdAt": "Créer le",
     "updatedDate": "Dernière mise à jour",
     "actions": "Actions",
     "reopenTicket": "Réouvrir",
     "closeTicket": "Fermer",
     "selectRows": "Sélectionner des lignes",
     "showAll": "Afficher tout",
     "previous": "Précédent",
     "next": "Suivant",
     "rows": "lignes",
     "confirmationCloseMsg": "Êtes-vous sûr de vouloir clôturer le ticket?",
     "confirmationOpenMsg": "Êtes-vous sûr de vouloir réouvrir le ticket?",
     "closeMsg": "Ticket fermé avec succès.",
     "openMsg": "Ticket ouvert avec succès.",
     "reopenMsg": "Ticket réouvert avec succès.",
     "successMsg": "Action réussie",
     "dialogTitleClose": "Clôturer le ticket",
     "dialogTitleOpen": "Réouvrir le ticket",
     "showing": "Affichage de",
     "to": "à",
     "of": "sur",
     "results": "résultats",
     "searchStatus": "Recherche status",
     "searchDate": "JJ/MM/AAAA",
     "ticketList": "Liste des tickets"
    },
    "documentTable": {
     "allDocuments": "Tous les documents",
     "yourDocuments": "Vos documents"
    },
    "callqueue": {
     "buttonTitle": "Nouvelle file d'attente",
     "buttonTitleGroup": "Nouveau groupe de files d'attente",
     "newFormTitle": "File d'attente de routages horaires",
     "newFormTitleGroup": "Groupe de file d'attente",
     "updateException": "Modifier le jour exceptionnel",
     "updateTimeSlot": "Modifier le créneau horaire",
     "updateFormTitle": "Modifier la file d'attente",
     "updateFormTitleGroup": "Modifier le groupe de file d'attente",
     "formSubTitle": "Ajouter les informations de la nouvelle file d'attente ci-dessous.",
     "formSubTitleGroup": "Ajouter les informations du nouveau groupe de file d'attente ci-dessous.",
     "subTitle": "Mettre à jour en complétant les informations ci-dessous"
    },
    "callqueueTable": {
     "name": "Nom",
     "number": "Numéro de file",
     "weekdayAndDate": "Jour de la semaine ou date",
     "weekday": "Jour de la semaine",
     "specificDate": "Date précise",
     "timeslot": "Plages horaires",
     "exception": "Jours exceptionnels",
     "reference": "Référence",
     "addTimeSlot": "Nouvelle plage horaire",
     "addException": "Nouveau jour exceptionnel",
     "addExceptionTwelveMonths": "Ajouter les jours fériés des 12 prochains mois",
     "formTimeSlotDescription": "Veuillez sélectionner un jour ou une plage de jours suivis des créneaux horaires associés",
     "formExceptionDescription": "Veuillez ajouter une exception à la liste d'appels en sélectionnant un jour spécifique",
     "formExceptionTwelveMonthsDescription": "Voici les douze prochains jours fériés pour la France",
     "selectAllDays": "Tous les jours de la semaine",
     "selectAllWorkingDays": "Tous les jours du Lundi au Vendredi",
     "inputSelectDays": "Sélectionner plusieurs jours en utilisant",
     "or": "ou",
     "day": "Jour",
     "dateSelected": "Date sélectionnée",
     "daySelected": "Jour(s) sélectionné(s)",
     "start": "Début",
     "end": "Fin",
     "checkStart": "Le début ne peut pas se situer dans une plage horaire existante",
     "checkEnd": "La fin ne peut pas se situer dans une plage horaire existante",
     "checkBetween": "Cette plage horaire est en conflit avec un créneau existant",
     "pickedStartBigger": "Le début ne peut pas être après ou identique à la fin",
     "newExceptionAdded": "Nouveau jour exceptionnel choisie",
     "newExceptionError": "Désolé, il y a une erreur dans la date choisie",
     "newExceptionExist": "Ce jour exceptionnel existe déjà",
     "selectExceptionError": "Sélectionnez un jour dans le calendrier avant de continuer",
     "destinationDescription": "Vous pouvez préciser une destination ici. L'API retourne une information relative à l'ouverture ou à la fermeture de la file d'attente en fonction de l'horaire. Ne précisez pas de destination ici si votre call flow appelle l'API ultérieurement pour router l'appel (routage géographique ou intelligent...)",
     "nextFrenchHolidays": "Jours fériés français sur les 12 prochains mois",
     "addFrenchHolidaysWarning": "Vous ajouterez automatiquement des exceptions pour toutes les dates spécifiées ci-dessus",
     "continue": "Souhaitez-vous continuer ?"
    },
    "weekdays": {
     "mon": "lundi",
     "tue": "mardi",
     "wed": "mercredi",
     "thu": "jeudi",
     "fri": "vendredi",
     "sat": "samedi",
     "sun": "dimanche"
    },
    "dialog": {
     "confirm": "Confirmer",
     "goBack": "Annuler"
    },
    "profile": {
     "userInfo": "Informations de l'utilisateur",
     "personalDetails": "Détails personnels",
     "fullName": "Nom et prénom",
     "apiToken": "API Token",
     "email": "Adresse e-mail",
     "activeAccount": "Compte Actif",
     "accounts": "Comptes"
    },
    "contactsForm": {
     "searchAccount": "Search account",
     "searchCompany": "Search company",
     "searchName": "Search name",
     "code": "Code",
     "searchNumber": "Search number",
     "contactAdded": "Contact added successfully",
     "contactUpdated": "Contact updated successfully",
     "contactDeleted": "Contact deleted successfully",
     "addContact": "Ajouter une entrée",
     "uploadContact": "ajouter plusieurs entrées (fichier)",
     "companyName": "Société",
     "identificationCode": "Code d'identification du client",
     "firstName": "Prénom",
     "lastName": "Nom",
     "vip": "Client VIP",
     "onCall": "Client sous contrat d'astreinte"
    },
    "pbx": {
     "newGroup": "Nouveau Groupe",
     "extensionNewGroupForm": "Formulaire pour créer un groupe d'extension",
     "extensionFormSubtittle": "Ajouter les information du nouveau groupe ci-dessous",
     "queueNewGroupForm": "Formulaire pour créer un groupe de file d'attente",
     "queueFormSubtittle": "Ajouter les information du nouveau groupe ci-dessous",
     "more": "autres sélections...",
     "and": "et"
    },
    "pbxReport": {
     "shareTitle": "Partager ce rapport",
     "shareMessage": "Vous voulez partager ce rapport avec les paramètres suivants",
     "shareScheduleTitle": "Partager un rapport planifié",
     "shareScheduleMessage": "Vous voulez partager un rapport planifié avec les paramètres suivants",
     "content": "Veuillez ajouter les destinataires pour compléter l'envoi. Si aucun email n'est fourni, le rapport vous sera envoyé.",
     "inputError": "Fournissez une email valide ou une adresse ne figurant pas dans la liste ci-dessous.",
     "emailRegexError": "Fournissez une email valide.",
     "emailAlreadyExistError": "Fournissez une adresse email qui n'a pas déjà été selectionner.",
     "nicknamePlaceholder": "Nom du rapport",
     "successMsg": "Rapport envoyé avec succès"
    }
   }
   