import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import VueApexCharts from "vue3-apexcharts";
import store from "./store";
import "./assets/tailwind.css";
import axios from "axios";
import i18n from "./i18n";
import CKEditor from "@ckeditor/ckeditor5-vue";
import VCalendar from "v-calendar";
import "./assets/css/main.css";
import ErrorHandling from "./mixins/ErrorHandling";
import { useCookie, VueCookieNext } from "vue-cookie-next";
import titleMixin from "./mixins/titleMixin";
import ExpertStats from "./mixins/ExpertStats";
import VueNextSelect from "vue-next-select";
import timeFormater from "./mixins/Formaters";

import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faCalendar,
  faCalendarDay,
  faCalendarDays,
  faCalendarWeek,
  faClock,
  faChartPie,
  faChartBar,
  faClipboardList,
  faComment,
  faEnvelope,
  faEnvelopeOpenText,
  faFileInvoiceDollar,
  faFilePdf,
  faFilterCircleXmark,
  faGear,
  faHeadset,
  faPhone,
  faRssSquare,
  faRightFromBracket,
  faRightToBracket,
  faSms,
  faServer,
  faShare,
  faTags,
  faUserTie,
  faWrench,
  faArchive,
} from "@fortawesome/free-solid-svg-icons";
import { faCalendarCheck } from "@fortawesome/free-regular-svg-icons";

library.add(
  faCalendar,
  faCalendarCheck,
  faCalendarDay,
  faCalendarDays,
  faCalendarWeek,
  faClock,
  faChartPie,
  faChartBar,
  faClipboardList,
  faComment,
  faEnvelope,
  faEnvelopeOpenText,
  faFileInvoiceDollar,
  faFilePdf,
  faFilterCircleXmark,
  faGear,
  faHeadset,
  faPhone,
  faRssSquare,
  faRightFromBracket,
  faRightToBracket,
  faSms,
  faServer,
  faShare,
  faTags,
  faUserTie,
  faWrench,
  faArchive
);

axios.defaults.baseURL = VueCookieNext.getCookie("API");

createApp(App)
  .use(VCalendar, {})
  .use(CKEditor)
  .use(i18n)
  .use(store)
  .use(router)
  .use(VueApexCharts)
  .use(VueCookieNext)
  .component("font-awesome-icon", FontAwesomeIcon)
  .component("vue-select", VueNextSelect)
  .mixin(titleMixin)
  .mixin(ExpertStats)
  .mixin(timeFormater)
  .mixin(ErrorHandling)
  .mount("#app");
